import React from "react";
import { classiBazarLogo } from "../../Asset/Assest";
import { GrClose } from "react-icons/gr";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Button from "../Button/Button";
import { fetchGetNotification } from "../../Redux/Notification/action";
import { scrollToTop } from "../NavigateTop";
import Loading from "../Loading/Loading";


const MobileNotification = ({
  showNotification,
  notification,
  setShowNotification,
  setPage,
  page
}) => {

  const dispatch = useDispatch()
  const token = Cookies.get("token");

  const nextPage = useSelector(state => state.notification.has_next_page)
  const isloading = useSelector(state => state.notification.isloading)
  const currPage = useSelector(state => state.notification.currentPage)

  const handlePrevData = () => {
    scrollToTop()
    setPage(page - 1)
    dispatch(fetchGetNotification(page - 1, token));
  }

  const handleNextData = () => {
    scrollToTop()
    setPage(page + 1)
    dispatch(fetchGetNotification(page + 1, token));
  }


  return (
    <div
      className={`pb-20 block lg:hidden bg-white fixed left-0 p-5 top-16 md:top-24 h-full w-full md:w-3/4 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  
      ${showNotification ? "-translate-x-0" : "-translate-x-full"}`}
    >
      <div className="w-full flex items-center justify-between">
        <h5 className="font-medium text-base md:text-xl">Notifications</h5>
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => setShowNotification(false)}
        >
          <GrClose className="text-xl sm:text-2xl md:text-3xl" />
        </div>
      </div>
      <hr className="w-full mt-2" />

      {isloading ? (
        <div className="w-full bg-white h-screen">
          <Loading />
        </div>
      ) : (
        notification && notification.length ? (
          notification.map((val) => {
            return (
              <div
                className="flex mt-5 gap-x-5 w-full cursor-pointer hover:shadow-md overflow-hidden  border rounded-lg p-2"
                key={val.id}
              >
                <div className="w-10 h-10">
                  <img
                    src={classiBazarLogo}
                    alt=""
                    className="w-full h-full rounded-full"
                  />
                </div>

                <div className="w-full overflow-hidden">
                  <h6 className="text-base font-medium truncate">{val.title}</h6>
                  <p className="text-textSecondary mt-2 text-sm">{val.body}</p>
                </div>
              </div>
            );
          })
        ) : (
          <h5 className="flex justify-center items-center w-full h-full">
            No Notification Yet
          </h5>
        )
      )}



      <div className="flex mt-5 gap-x-5 w-full cursor-pointer hover:shadow-md overflow-hidden  border rounded-lg p-2">
        {/* {console.log(currPage)} */}
        {currPage > 1 &&
          <div>
            <Button
              value="Prev"
              className="bg-gray-50 text-black rounded-xl border py-2 px-5"
              handleClick={handlePrevData}
            />
          </div>
        }
        {nextPage &&
          <div className="ml-auto">
            <Button
              value="Next"
              className="bg-gray-50 text-black rounded-xl border py-2 px-5"
              handleClick={handleNextData}
            />
          </div>
        }
      </div>

    </div>
  );
};

export default MobileNotification;
