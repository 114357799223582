import React, { useEffect, useState } from "react";
import { googleIcon, logo } from "../../Asset/Assest";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../Component";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchLogin,
  fetchSocialLogin,
  removeAuthData,
} from "../../Redux/Auth/action";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import Loading from "../../Component/Loading/Loading";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.auth.message, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  //   initial values
  const initialValues = {
    email: "",
    password: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required*"),
    password: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchLogin(values));
    setInProgress(true);
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      dispatch(removeAuthData());
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setInProgress(false);
    message && navigate("/");
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setInProgress(false);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        })
        .then(({ data }) => {
          const body = {
            email: data.email,
            first_name: data.given_name,
            last_name: data.family_name,
            token: data.id,
          };
          scrollToTop();
          dispatch(fetchSocialLogin(body));
          setInProgress(true);
        });
    },
  });

  if (inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-12 w-full">
      <div className="w-11/12 sm:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto border bg-white shadow-md p-5 sm:p-7 sm:mt-10 rounded-xl">
        <div className="flex justify-center items-center flex-col">
          <div className="w-1/2 sm:w-5/12 h-8 sm:h-12 xl:h-9">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <h4 className="font-semibold text-textPrimary text-2xl mt-6">
            Welcome Back
          </h4>
        </div>

        <div className="mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(fornik) => (
              <Form>
                <div className="w-full">
                  <FormikControl
                    label="Email"
                    control="input"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="w-full mt-5">
                  <FormikControl
                    label="Password"
                    control="password"
                    name="password"
                    placeholder="Enter Your Password"
                    type={`${showPassword ? "text" : "password"}`}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                </div>
                <div className="w-full mt-7">
                  <Button
                    value="Sign in"
                    type="submit"
                    className="bg-primary py-3.5 w-full rounded-xl text-white"
                  />
                </div>
              </Form>
            )}
          </Formik>
          <h4
            className="text-primary mt-5 text-lg cursor-pointer text-center font-medium hover:underline"
            onClick={() => navigate("/auth/forgot-password")}
          >
            Forgot Your Password?
          </h4>
          <div className="flex items-center gap-x-4 mt-10">
            <hr className="w-full text-textSecondary border" />
            <p className="text-textPrimary">OR</p>
            <hr className="w-full text-textSecondary border" />
          </div>

          <div
            onClick={() => googleLogin()}
            className="w-11/12 md:w-3/4 xl:w-11/12 2xl:w-3/4 mx-auto rounded-full py-3 px-5 md:pr-16 cursor-pointer mt-5 border flex justify-between items-center transition duration-1000 ease-in-out hover:bg-gray-100"
          >
            <img src={googleIcon} alt="" />
            <p className="text-center">Continue with Google</p>
          </div>
          {/* <div className="w-11/12 md:w-3/4 xl:w-11/12 2xl:w-3/4 mx-auto rounded-full py-3 px-5 md:pr-16 cursor-pointer mt-5 border flex justify-between items-center transition duration-1000 ease-in-out hover:bg-gray-100">
            <img src={appleIcon} alt="" />
            <p className="text-center">Continue with Apple</p>
          </div> */}
          <p className="font-medium text-lg text-textSecondary mt-10 text-center">
            By proceeding, you agree to the{" "}
            <span className="text-green-600 cursor-pointer hover:underline">
              <Link to="/terms-of-use">Terms & Conditions </Link>
            </span>
            and{" "}
            <span className="text-green-600 cursor-pointer hover:underline">
              <Link to="/privacy-policy">Privacy Policies </Link>
            </span>
          </p>
          <p className="text-center text-lg text-textSecondary mt-7">
            Don't have account?{" "}
            <Link to="/auth/sign-up">
              <span className="text-primary font-semibold cursor-pointer hover:underline">
                Sign up
              </span>
            </Link>
          </p>
          <div className="flex items-center justify-center gap-x-3 w-3/4 mx-auto mt-10 text-textSecondary">
            <p className="cursor-pointer hover:underline">
              <Link to="/terms-of-use">Terms</Link>
            </p>
            <div className="border-r-2 h-6 text-textSecondary"></div>
            <p className="cursor-pointer hover:underline">
              <Link to="/privacy-policy">Privacy</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
