import React from "react";
import { calling, house2, sms } from "../../Asset/Assest";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../Component";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    fetchSendContact,
    removeContactData,
} from "../../Redux/ContactUs/action";
import { toast } from "react-toastify";

const ContactUs = () => {
    const message = useSelector((state) => state.contact.message, shallowEqual);
    const errMessage = useSelector((state) => state.contact.errMsg, shallowEqual);

    const dispatch = useDispatch();

    //   initial values
    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        msg: "",
    };

    // check formik validation
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("Required*"),
        last_name: Yup.string().required("Required*"),
        email: Yup.string().required("Required*"),
        msg: Yup.string().required("Required*"),
    });

    const onSubmit = (values, onSubmitProps) => {
        scrollToTop();
        const body = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            msg: values.msg,
            contact_from: "ClassiLeads",
        };

        console.log(body)
        dispatch(fetchSendContact(body));
        setTimeout(() => {
            onSubmitProps.setSubmitting(false);
            onSubmitProps.resetForm({ value: "" });
        }, 1000);
        toast.info("Sending... Please wait.")
    };

    useEffect(() => {
        message && toast.success(message);
        message && dispatch(removeContactData());
    }, [message]);

    useEffect(() => {
        errMessage && toast.success(errMessage);
        errMessage && dispatch(removeContactData());
    }, [errMessage]);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className="py-16 md:py-24 w-11/12 xl:w-3/4 mx-auto">
            <div className="mt-10 md:mt-20 grid md:grid-cols-2 gap-y-20 md:gap-y-0 w-full md:gap-x-5 h-full">
                <div className="w-full h-full">
                    <h4 className="font-semibold text-3xl text-textPrimary">
                        Get in Touch
                    </h4>
                    <p className="text-base text-textSecondary mt-5 md:mt-8">
                        Feel free to send us a message using the contact form below. We will
                        get back to you as soon as possible.
                    </p>

                    <div className="mt-10 flex items-center gap-x-5">
                        <div className="border bg-primary p-3 w-fit rounded-xl">
                            <img src={house2} alt="" className="" />
                        </div>
                        <div>
                            <p className="text-lg font-medium">Our Location</p>
                            <p className="text-sm mt-1 text-textSecondary">
                                Suite 203, Level 2, 189 Kent Street, Sydney NSW 2000
                            </p>
                        </div>
                    </div>

                    <div className="mt-10 flex items-center gap-x-5">
                        <div className="border bg-primary p-3 w-fit rounded-xl">
                            <img src={calling} alt="" className="" />
                        </div>
                        <div>
                            <p className="text-lg font-medium">Phone Number</p>
                            <p className="text-sm mt-1 text-textSecondary">
                                1300 287 637, 02 7226 3665, 0451 933 257
                            </p>
                        </div>
                    </div>

                    <div className="mt-10 flex items-center gap-x-5">
                        <div className="border bg-primary p-3 w-fit rounded-xl">
                            <img src={sms} alt="" className="" />
                        </div>
                        <div>
                            <p className="text-lg font-medium">Email Address</p>
                            <p className="text-sm mt-1 text-textSecondary">
                                hello@classiestate.com.au
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full h-full border rounded-xl shadow-md p-5 lg:p-8">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnMount
                    >
                        {(formik) => (
                            <Form>
                                <div className="w-full">
                                    <FormikControl
                                        label="Your First Name"
                                        control="input"
                                        name="first_name"
                                        placeholder="Enter Your First Name"
                                    />
                                </div>

                                <div className="w-full mt-7">
                                    <FormikControl
                                        label="Your Last Name"
                                        control="input"
                                        name="last_name"
                                        placeholder="Enter Your Last Name"
                                    />
                                </div>

                                <div className="w-full mt-7">
                                    <FormikControl
                                        label="Your Email"
                                        control="input"
                                        name="email"
                                        placeholder="Enter Your Email"
                                    />
                                </div>

                                <div className="w-full mt-7">
                                    <FormikControl
                                        label="Message"
                                        control="textarea"
                                        name="msg"
                                        placeholder="Tell us about your query"
                                    />
                                </div>
                                <div className="w-full mt-7">
                                    <Button
                                        value="Send Message"
                                        className="w-full bg-primary text-white rounded-xl py-3 md:py-4"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
