import Cookies from "js-cookie";
import React, { useState } from "react";
import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";
import { AccountHeader } from "../Component";
import { BiCategory } from "react-icons/bi";
import MobileAccountHeader from "../Component/Header/MobileAccountHeader";

const AccountRoute = ({ Component }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { pathname } = useLocation();
  const pathroad = matchPath("/me", pathname);

  const token = Cookies.get("token");

  if (!token) {
    return <Navigate to={`/auth/sign-in`} replace />;
  }
  return (
    <div className="bg-gray-50 pb-10">
      <div className="w-11/12 mx-auto xl:w-3/4 relative ">
        <div className="mt-7 md:mt-10 flex items-center justify-between">
          <h4 className="font-medium text-2xl md:text-3xl text-textPrimary">
            My Account
          </h4>
          <BiCategory
            className="block md:hidden text-3xl text-textSecondary"
            onClick={() => setMobileMenu(!mobileMenu)}
          />
        </div>
        <hr className="w-full mt-5 md:mt-7" />

        <div className="grid grid-cols-12 mt-5 md:mt-7 gap-x-5">
          <div className="hidden md:block md:col-span-4 lg:col-span-3 w-full h-full">
            <AccountHeader />
          </div>
          <div className="col-span-12 md:col-start-5 lg:col-start-4 md:col-end-13 w-full h-full">
            {pathroad ? <Component /> : <Outlet />}
          </div>
        </div>
        <MobileAccountHeader
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
        />
      </div>

      {mobileMenu && (
        <div
          className="w-full h-full absolute top-0  bg-black opacity-10"
          onClick={() => setMobileMenu(false)}
        ></div>
      )}
    </div>
  );
};
export default AccountRoute;
