import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const directoryLoading = () => ({
  type: ActionTypes.DIRECTORY_LOADING,
});

export const removeDirectoryData = () => ({
  type: ActionTypes.REMOVE_DIRECTORY,
});

export const fetchGetDirectorySuggestions = (key) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/lead_leave_category?keyword=${key}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DIRECTORY_SUGGESTION,
        payload: data,
      });
    });
};

export const fetchGetDirectories = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/lead_leave_category`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DIRECTORY,
        payload: data,
      });
    });
};

export const fetchGetDirectoryDetail = (id) => async (dispatch) => {
  await axios.get(`${BaseUrl}/lead_category/${id}`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_DIRECTORY_DETAIL,
      payload: data,
    });
  });
};
