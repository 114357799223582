export const PROFILE_LOADING = "PROFILE_LOADING";
export const GET_SAVED_PROPERTY = "GET_SAVED_PROPERTY";
export const FAIL_GET_SAVED_PROPERTY = "FAIL_GET_SAVED_PROPERTY";
export const GET_AGENT_ENQUIRIES = "GET_AGENT_ENQUIRIES";
export const GET_AGENT_ENQUIRY_DETAIL = "GET_AGENT_ENQUIRY_DETAIL";
export const GET_MY_INSPECTION = "GET_MY_INSPECTION";
export const DELETE_INSPECTION_SUCCESS = "DELETE_INSPECTION_SUCCESS";
export const DELETE_INSPECTION_FAIL = "DELETE_INSPECTION_FAIL";
export const ADD_INSPECTION_SUCCESS = "ADD_INSPECTION_SUCCESS";
export const ADD_INSPECTION_FAIL = "ADD_INSPECTION_FAIL";
export const UPLOAD_PROFILE_SUCCESSS = "UPLOAD_PROFILE_SUCCESS";
export const UPLOAD_PROFILE_FAIL = "UPLOAD_PROFILE_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const VERIFY_MOBILE_SUCCESS = "VERIFY_MOBILE_SUCCESS";
export const VERIFY_MOBILE_FAIL = "VERIFY_MOBILE_FAIL";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL";
export const REMOVE_PROILE_DATA = "REMOVE_PROFILE_DATA";
