import React from "react";
import { Button } from "../../../Component";
const data = [
  {
    id: 1,
    label: "I am flexible",
    value: "I am flexible",
  },
  {
    id: 2,
    label: "ASAP",
    value: "ASAP",
  },
  {
    id: 3,
    label: "By This Week",
    value: "By This Week",
  },
  {
    id: 4,
    label: "By This Month",
    value: "By This Month",
  },
];

const WhenStart = ({ formData, setFormData, setComponents, setLocation }) => {
  return (
    <div className="mt-10 w-full">
      <div className="p-5 border rounded-xl bg-white">
        <h4 className="text-textPrimary text-xl font-semibold">
          When do you need it?
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="w-full mt-5 grid grid-cols-3 gap-x-5">
          {data.map((val) => {
            return (
              <h6
                key={val.id}
                onClick={() => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      when_start: val.value,
                    };
                  })


                  setComponents({
                    location: false,
                    when_start: false,
                    photo: true,
                    dynamic: false,
                    details: false,
                  });


                }}
                className={`w-full border rounded-xl mt-5 py-4 text-center cursor-pointer ${formData.when_start === val.value
                  ? "bg-purple-50 border-primary"
                  : ""
                  }`}
              >
                {val.label}
              </h6>
            );
          })}
        </div>
      </div>

      <div className="mt-5 flex items-center justify-end gap-x-5">
        <Button
          value="Back"
          handleClick={() => {
            setComponents({
              location: true,
              when_start: false,
              photo: false,
              dynamic: false,
              details: false,
            });
            setLocation("")
          }}
          className="w-1/5 bg-gray-50 rounded-xl border py-3"
        />
        {/* {formData.when_start && (
          <Button
            value="Next"
            handleClick={() => {
              setComponents({
                location: false,
                when_start: false,
                photo: true,
                dynamic: false,
                details: false,
              });
            }}
            className="w-1/5 bg-primary text-white rounded-xl py-3"
          />
        )} */}
      </div>
    </div>
  );
};

export default WhenStart;
