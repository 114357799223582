import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  categories: [],
};

export const Category = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LEAD_CATEGORY:
      return {
        ...state,
        isloading: false,
        success: true,
        categories: action.payload,
      };

    case ActionTypes.REMOVE_CATEGORY:
      return {
        isloading: true,
        success: false,
        categories: [],
      };

    default:
      return state;
  }
};
