import React from "react";
import { NavLink } from "react-router-dom";

const menu = [
  {
    id: 1,
    name: "Profile",
    link: "/me",
  },

  // {
  //   id: 2,
  //   name: "My Draft",
  //   link: "/me/draft",
  // },

  {
    id: 4,
    name: "My Enquiries",
    link: "/me/enquiries",
  },

  // {
  //   id: 7,
  //   name: "Notification Setting",
  //   link: "/me/notification-settings",
  // },
];

const MobileAccountHeader = ({ mobileMenu, setMobileMenu }) => {
  return (
    <div
      className={` bg-white fixed left-0 top-16 pb-10 h-full w-2/3 sm:w-2/6 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        mobileMenu ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="w-full h-full px-3 py-5 flex flex-col gap-y-2 bg-white">
        {menu.map((val) => {
          return (
            <NavLink
              key={val.id}
              onClick={() => setMobileMenu(false)}
              end
              to={val.link}
              className={({ isActive, isPending }) =>
                isActive
                  ? " text-white rounded-xl text-base bg-primary py-3 px-5 font-medium"
                  : "text-sm py-3 text-textSecondary px-5 hover:text-textPrimary"
              }
            >
              {val.name}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAccountHeader;
