import React, { useEffect, useState } from "react";
import { Button } from "../../Component";
import ListItems from "./ListItems";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { fetchGetMyEnquiry, fetchGetMyHiredEnquiry, removeEnquiryData } from "../../Redux/Enquiry/action";
import Loading from "../../Component/Loading/Loading";
import { choices } from './constants'
import { scrollToTop } from "../../Component/NavigateTop";
import { formatDate } from "../../utils/formatDate";


const MyEnquiries = () => {
  const token = Cookies.get("token");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.enquiry.isloading, shallowEqual);
  // const leadData = useSelector((state) => state.enquiry.myEnquiries, shallowEqual);
  const leadData = useSelector((state) => state.enquiry.hiredEnquiries, shallowEqual);
  // const leadHiredData = useSelector((state) => state.enquiry.hiredEnquiries, shallowEqual);

  // console.log(leadData)
  // console.log(leadHiredData)



  // console.log("lead", lead)


  const [isStatus, setIsStatus] = useState("Pending")
  const [activeFilter, setactiveFilter] = useState(choices.active)

  // useEffect(() => {
  //   dispatch(fetchGetMyEnquiry(token));
  //   return () => { dispatch(removeEnquiryData()) }
  // }, [token, dispatch]);


  useEffect(() => {
    dispatch(fetchGetMyHiredEnquiry(token, 1, isStatus));
    return () => { dispatch(removeEnquiryData()) }
  }, [token, isStatus, dispatch]);


  // const displayArr = useMemo(() => {
  //   const { active, hired, closed } = choices

  //   return lead?.filter((val) => {
  //     if (activeFilter === active) return val.status === "Pending"
  //     else if (activeFilter === hired) return val.status === "Hired"
  //     // else if (activeFilter === hired) return setIsStatus("Hired")
  //     else if (activeFilter === closed) return val.status === "Closed"
  //     return true;
  //   })
  // }, [activeFilter, lead])
  // const lead = leadData
  const displayArr = leadData?.requests

  // console.log(displayArr, "DisplayArr")


  const [currentPage, setCurrentPage] = useState(1)

  const handleChoices = (choice) => {
    setactiveFilter(choice)

    setIsStatus(() => {
      if (choice === "Active") {
        return "Pending"
      } else return choice
    })
  }

  const handlePrevData = () => {
    scrollToTop()
    setCurrentPage(currentPage - 1)
    dispatch(fetchGetMyHiredEnquiry(token, currentPage - 1, isStatus));
    dispatch(removeEnquiryData())
  }

  const handleNextData = () => {
    scrollToTop()
    setCurrentPage(currentPage + 1)
    dispatch(fetchGetMyHiredEnquiry(token, currentPage + 1, isStatus));
    dispatch(removeEnquiryData())
  }



  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="space-y-6">

      <h5 className="font-semibold text-2xl">My Enquiries</h5>

      <div className="w-full overflow-y-auto mx-0 px-0">

        <div className="flex mr-auto justify-start items-center">
          {Object.entries(choices).map(([key, value]) => (
            <Button
              key={key}
              value={value}
              handleClick={() => handleChoices(value)}
              className={`${activeFilter === value ? "border-primary text-black" : "text-[#70747D]"} 
              w-full whitespace-nowrap border-b-2 py-2 px-4 lg:px-6 text-sm lg:text-base`}
            />
          ))}
        </div>
      </div>
      {console.log("nextpage", displayArr)}
      <div className="pt-2 space-y-8">
        {displayArr?.map((val) => (
          <ListItems
            key={val.id}
            id={val.id}
            name={val.name}
            date={formatDate(val.added_at)}
            address={val.address}
            leadData={val.lead_data}
            metaDesc={val.meta_desc}
            // status={val.status}
            isStatus={isStatus}
            // agents={val?.agents.length ? val.agents[0] : null}
            agents={isStatus === "Hired" || isStatus === "Closed" ? val.agents[0] : null}
          />
        ))}
      </div>


      {leadData?.current_page !== 1 & leadData?.has_next_page ?
        <>
          <div className="w-full p-3 border bg-white rounded-lg justify-between flex items-center">
            {leadData?.current_page > 1 &&
              <div>
                <Button
                  value="Prev"
                  className="bg-gray-50 rounded-xl border py-2 px-5"
                  handleClick={handlePrevData}
                />
              </div>
            }
            {leadData?.has_next_page &&
              <div className="ml-auto">
                <Button
                  value="Next"
                  className="bg-gray-50 rounded-xl border py-2 px-5"
                  handleClick={handleNextData}
                />
              </div>
            }
          </div>
        </>
        : null
      }

    </div >
  );
};

export default MyEnquiries;



// const [loading, setLoading] = useState(false);
// const [enquiryData, setEnquiryData] = useState([...store?.enquiry?.activeList?.requests]);
// const [hiredRequestData, setHiredRequestData] = useState([...store?.enquiry?.hiredList?.requests]);
// const [closeRequestData, setClosedRequestData] = useState([...store?.enquiry?.closedList?.requests]);


// const handlePagination = (page = 1) => {
//   setLoading(true);
//   let countPage = parseInt(page)
// + 1
//   dispatch(
//     allActions(
//       {},
//       {
//         method: 'GET',
//         endPoint: `/v3/lead_request?page=${countPage}`,
//         attempt: 'GET_ACTIVE_ENQUIRY_LIST_FETCHING_DATA_ATTEMPT',
//         success: 'GET_ACTIVE_ENQUIRY_LIST_FETCHING_DATA_SUCCESS',
//         failure: 'GET_ACTIVE_ENQUIRY_LIST_FETCHING_DATA_FAILURE',
//         successInternalState: (response) => {
//           if (response?.current_page === 1) {
//             setEnquiryData([...enquiryData, ...response?.requests]);
//             setLoading(false);
//           } else {
//             setEnquiryData([...response?.requests]);
//             setLoading(false)
//           }
//         },
//         failureInternalState: () => {
//           setLoading(false);
//         },
//         saveBearerToken: false
//       }
//     )
//   );
// }