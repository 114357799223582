import React, { useEffect, useState } from "react";
import { logo } from "../../Asset/Assest";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import Button from "../Button/Button";
import MobileMenu from "./MobileMenu";
import { scrollToTop } from "../NavigateTop";
import Cookies from "js-cookie";
import { IoIosNotifications } from "react-icons/io";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Avatar from "react-avatar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetNotification,
  fetchMakeNotificationSeen,
  removeNotificationData,
} from "../../Redux/Notification/action";
import Notification from "../Notification/Notification";
import MobileNotification from "../Notification/MobileNotification";
import { fetchGetUserProfile } from "../../Redux/UserProfile/action";

const Header = () => {
  const [headerShadow, setHeaderShadow] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [page, setPage] = useState(1);

  const profile = useSelector((state) => state.profiles.profile, shallowEqual);

  const notification = useSelector(
    (state) => state.notification.notification,
    shallowEqual
  );
  const noOfUnseen = useSelector(
    (state) => state.notification.unSeen,
    shallowEqual
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const handleClickLogo = () => {
    navigate("/");
    scrollToTop();
    window.location.reload(false);
  };

  const handleClickLogout = () => {
    Cookies.remove("token");
    window.location.reload(false);
  };

  const handleClickNotiication = () => {
    setShowNotification(!showNotification);
    setShowProfile(false);
    dispatch(fetchMakeNotificationSeen(token));
    setTimeout(() => {
      dispatch(fetchGetNotification(page, token));
    }, 1000);
  };

  useEffect(() => {
    token && dispatch(fetchGetUserProfile(token));
  }, [token]);

  useEffect(() => {
    token && dispatch(fetchGetNotification(page, token));
    return () => {
      dispatch(removeNotificationData());
    };
  }, [page]);

  const applyShadow = () => {
    if (window.scrollY >= 30) {
      setHeaderShadow(true);
    } else {
      setHeaderShadow(false);
    }
  };

  window.addEventListener("scroll", applyShadow);
  return (
    <div
      className={`h-16 sm:h-20 md:h-24 border-b sticky top-0 z-50 bg-white w-full ${
        headerShadow ? "shadow-sm" : ""
      }`}
    >
      {showNotification && (
        <Notification
          notification={notification}
          setPage={setPage}
          page={page}
        />
      )}
      <div className="w-11/12 mx-auto gap-x-5 grid grid-cols-12 h-full content-center relative">
        {showProfile && (
          <div className="w-1/4 border py-6 px-5 rounded-xl bg-white z-50 shadow-md absolute top-16 2xl:top-20 right-0">
            <h6 className="text-textPrimary font-bold text-lg">
              {profile && profile.first_name} {profile && profile.last_name}
            </h6>
            <p className="text-textSecondary text-sm mt-2">
              {profile && profile.user_email}
            </p>
            <hr className="mt-5" />
            <Link to="/me">
              <p
                className="text-primary mt-4 text-lg cursor-pointer hover:underline"
                onClick={() => setShowProfile(false)}
              >
                My Account
              </p>
            </Link>
            <p
              className="text-primary mt-3 text-lg cursor-pointer hover:underline"
              onClick={handleClickLogout}
            >
              Logout
            </p>
          </div>
        )}
        <div className="col-span-6 sm:col-span-4 lg:col-span-2 py-2 lg:py-2.5 2xl:py-2">
          <img
            src={logo}
            alt="logo"
            className="w-2/3 2xl:w-3/4 lg:h-7 2xl:h-9 cursor-pointer object-contain"
            onClick={handleClickLogo}
          />
        </div>

        <div className="hidden lg:block col-start-10 col-end-13 py-2">
          {token ? (
            <div className="flex items-center justify-end gap-x-5 xl:gap-x-10">
              <div
                className="relative border rounded-full p-2"
                onClick={handleClickNotiication}
              >
                <IoIosNotifications className="text-xl sm:text-2xl md:text-3xl cursor-pointer text-gray-500 hover:text-textSecondary" />

                {noOfUnseen ? (
                  <p className="absolute text-xs top-0 right-0 text-white px-1 bg-red-500 rounded-full border">
                    {noOfUnseen}
                  </p>
                ) : null}
              </div>
              <div
                className="flex items-center gap-x-2 cursor-pointer"
                onClick={() => {
                  setShowProfile(!showProfile);
                  setShowNotification(false);
                }}
              >
                {profile && profile.image ? (
                  <div className="w-12 h-12">
                    <img
                      src={profile.image}
                      alt=""
                      className="w-full h-full rounded-full"
                    />{" "}
                  </div>
                ) : (
                  <Avatar
                    name={profile && profile.first_name}
                    size={40}
                    round={true}
                  />
                )}

                <MdOutlineKeyboardArrowDown className="text-lg" />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-end h-full gap-x-4 xl:gap-x-6 2xl:gap-x-10">
              <Button
                value="Sign in"
                className="text-primary font-medium text-xs xl:text-base"
                handleClick={() => navigate("/auth/sign-in")}
              />
              <Button
                value="Sign up"
                className="bg-primary py-3 px-4 2xl:px-7 text-xs xl:text-base rounded-lg text-white"
                handleClick={() => navigate("/auth/sign-up")}
              />
            </div>
          )}
        </div>

        {/* mobile device */}
        <div
          className="col-start-11 col-end-13 block lg:hidden py-2 justify-self-end cursor-pointer"
          onClick={() => {
            setMobileMenu(true);
            setShowNotification(false);
          }}
        >
          <AiOutlineMenu className="text-primary text-2xl sm:text-3xl md:text-4xl" />
        </div>
      </div>

      <MobileMenu
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        setShowNotification={setShowNotification}
        page={page}
      />

      <MobileNotification
        notification={notification}
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        setPage={setPage}
        page={page}
      />

      {mobileMenu || showProfile || showNotification ? (
        <div
          className="absolute w-full h-screen bg-black opacity-10"
          onClick={() => {
            setMobileMenu(false);
            setShowProfile(false);
            setShowNotification(false);
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default Header;
