import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  form_id: "",
  dynamic_field: [],
  myEnquiries: [],
  hiredEnquiries: [],
  companyDetail: [],
  enquiryDetail: {},
  errMsg: "",
  message: "",
};

export const Enquiry = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LEAD_FORM_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        dynamic_field: action.payload.template,
        form_id: action.payload.forms_id,
      };

    case ActionTypes.GET_LEAD_FORM_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_COMPANY_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        companyDetail: action.payload.enquiry_users,
      };

    case ActionTypes.GET_MY_HIRED_ENQUIRY:
      return {
        ...state,
        isloading: false,
        success: true,
        hiredEnquiries: action.payload,
      };

    case ActionTypes.SUBMIT_ENQUIRY_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SUBMIT_ENQUIRY_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_MY_ENQUIRY:
      return {
        ...state,
        isloading: false,
        success: true,
        myEnquiries: action.payload,
      };

    case ActionTypes.GET_MY_ENQUIRY_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        enquiryDetail: action.payload.lead,
      };

    case ActionTypes.REMOVE_ENQUIRY_DATA:
      return {
        isloading: true,
        success: false,
        form_id: "",
        dynamic_field: [],
        myEnquiries: [],
        companyDetail: [],
        hiredEnquiries: [],
        enquiryDetail: "",
        errMsg: "",
        message: "",
      };

    default:
      return state;
  }
};
