import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const categoryLoading = () => ({
  type: ActionTypes.CATEGORY_LOADING,
});

export const removeCategoryData = () => ({
  type: ActionTypes.REMOVE_CATEGORY,
});

export const fetchGetLeadCategory = (keyword) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/lead_category?keyword=${keyword}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_LEAD_CATEGORY,
        payload: data,
      });
    });
};
