import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetLeadCategory } from "../../Redux/Category/action";
import { classiBazarLogo, homeBg } from "../../Asset/Assest";
import { Button } from "../../Component";
import debounce from "lodash.debounce";
import { CiSearch } from "react-icons/ci";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading/Loading";
import { fetchGetLeadForm } from "../../Redux/Enquiry/action";
import Article from "./Article/Article";

const CategoryComponent = ({ setToggle, catId, setCatId }) => {
  const [keyword, setKeyword] = useState("");

  const [preserveInitialData, setPreserveInitialData] = useState([])

  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state.category.isloading,
    shallowEqual
  );

  const categories = useSelector(
    (state) => state.category.categories,
    shallowEqual
  );

  const handleClickNext = (cat_id) => {
    setCatId(cat_id)

    // if (catId) {
    if (cat_id) {
      setToggle({
        start: false,
        category: false,
        field: true,
      });

      // dispatch(fetchGetLeadForm(catId));
      dispatch(fetchGetLeadForm(cat_id));
    } else {
      toast.error("Please Select Category");
    }
  };

  const onchangeSearchHandler = (e) => {
    setKeyword(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    dispatch(fetchGetLeadCategory(keyword));
  }, [keyword]);


  // to preserve initial api data
  useEffect(() => {
    if (!keyword && categories && !preserveInitialData?.length) {
      setPreserveInitialData(categories)
    }
  }, [categories, keyword, preserveInitialData])



  return (
    <div className=" w-full">


      <div

        className="py-40 relative flex justify-start items-center"
      >

        <div className="absolute inset-0">
          <img src={homeBg} className=" transform scale-x-[-1] w-full h-full object-cover" alt="" />
        </div>


        <div className="bg-transparent z-10 w-full p-2 md:p-10">

          <div className="px-2 md:px-10">
            <h6 className="font-semibold text-2xl">
              Choose <span className="text-primary">Service</span>
            </h6>
            <p className="text-base mt-2">
              Please choose one service to <span> Get Started! </span>
            </p>

            <div className="relative mt-10 w-full md:w-1/2">
              <div className="bg-white border rounded-xl py-2.5 px-2 gap-x-2 flex items-center">

                <input
                  type="text"
                  placeholder="Search Category"
                  className="py-1 w-full outline-none px-3"
                  onChange={(e) => debouncedResults(e)}
                />
                <CiSearch size={27} className="text-textSecondary" />
              </div>

              {keyword ?
                <div className="max-h-52 overflow-y-auto absolute z-10 bg-white top-full w-full border">
                  {loading ? (
                    <div className="w-full h-full mt-5">
                      <Loading />
                    </div>
                  ) : (
                    <div>

                      {categories?.map((val, index) => (

                        <div
                          key={index}
                          className="py-2 px-4 border-b cursor-pointer hover:bg-gray-50"
                          onClick={() => { handleClickNext(val.cat_id) }}
                        >
                          {val.cat_name}
                        </div>
                      ))}
                    </div>
                  )}

                </div>
                :
                <></>
              }

            </div>
          </div>


        </div>


        {/* <div className="relative w-full md:block md:w1/2">
          <div className=" md:h-screen">
            <img src={homeBg} className="transform scale-x-[-1] w-full h-full object-cover" alt="" />
          </div>


          <div className="absolute top-1/2 left-0 md:translate-x-5 bgwhite rounded-lg -translate-y-1/2 w-full p-2 md:p-10">

            <div className="px-2 md:px-10">
              <h6 className="font-semibold text-2xl">
                Choose <span className="text-primary">Service</span>
              </h6>
              <p className="text-base mt-2">
                Please choose one service to <span> Get Started! </span>
              </p>

              <div className="relative mt-10 ">
                <div className="bg-white w-1/2 border rounded-xl w-1/2 py-2.5 px-2 gap-x-2 flex items-center">

                  <input
                    type="text"
                    placeholder="Search Category"
                    className="py-1 w-full outline-none px-3"
                    onChange={(e) => debouncedResults(e)}
                  />
                  <CiSearch size={27} className="text-textSecondary" />
                </div>

                {keyword ?
                  <div className="max-h-52 overflow-y-auto absolute z-10 bg-white top-full w-full border">
                    {loading ? (
                      <div className="w-full h-full mt-5">
                        <Loading />
                      </div>
                    ) : (
                      <div>

                        {categories?.map((val, index) => (

                          <div
                            key={index}
                            className="py-2 px-4 border-b cursor-pointer hover:bg-gray-50"
                            onClick={() => { handleClickNext(val.cat_id) }}
                          >
                            {val.cat_name}
                          </div>
                        ))}
                      </div>
                    )}

                  </div>
                  :
                  <></>
                }

              </div>
            </div>


          </div>
        </div> */}

      </div>


      <div className="w-11/12 xl:w-3/4 mx-auto py-10 ">

        <div className="mt-4 font-semibold text-2xl text-primary">Popular Services</div>

        {loading ? (
          <div className="w-full h-full mt-5">
            <Loading />
          </div>
        ) : (
          <>
            <div className="w-full mt-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-5">
              {/* {categories &&
              categories.map((val) => { */}
              {preserveInitialData &&
                preserveInitialData?.map((val) => {
                  return (
                    <div
                      key={val.cat_id}
                      // onClick={() => setCatId(val.cat_id)}
                      onClick={() => { handleClickNext(val.cat_id) }}
                      className={`${catId === val.cat_id ? "bg-purple-50 border-primary" : ""
                        } border hover:border-primary rounded-xl transition-all ease-in-out duration-500 py-5 cursor-pointer w-full mt-6`}
                    >
                      <div className="w-full h-12 overflow-hidden">
                        {val.cat_image ? (
                          <img
                            src={val.cat_image}
                            alt=""
                            className="w-full h-full object-contain"
                          />
                        ) : (
                          <img
                            src={classiBazarLogo}
                            alt=""
                            className="w-full h-full object-contain"
                          />
                        )}
                      </div>
                      <p className="text-center text-xs font-medium mt-4">
                        {val.cat_name}
                      </p>
                    </div>
                  );
                })}
            </div>


            {/* Not needed now. */}
            {/* <div className="mt-5 w-full flex justify-end">
            <Button
              value="Next"
              handleClick={handleClickNext}
              className="w-1/5  px-7 py-3 rounded-xl bg-primary text-white"
            />
          </div> */}

            <div className="my-20">
              <Article />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryComponent;
