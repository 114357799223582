import React from 'react'

// Cards for Carousel
function NewsCard(props) {

    const {
        id,
        image,
        title,
        desc,
        executeFunctions,

        // props from carousel
        handleMouseDown,
        handleMouseMove,
        setIsMouseDown,
        condition = true
    } = props

    const handleMouseUp = () => {
        setIsMouseDown && setIsMouseDown(false);

        if (condition) {
            executeFunctions(id)
        }
    };


    return (
        <div
            // To prevent unintended click behavior when selecting and swiping cards on the production side. This ensures the onClick function is only triggered on intended clicks, not during swipe gestures.
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className={`w-full h-full group duration-300 md:min-h-[18.25rem] border overflow-hidden rounded-lg flex flex-col justify-stretch items-center cursor-pointer gap-y-4`}
        >

            <div className="w-full overflow-hidden">
                <div className="max-w-80 w-full min-h-40 aspect-[13/9]">
                    <img src={image} className=" group-hover:scale-125 duration-200 w-full h-full object-cover" alt={""} />
                </div>
            </div>


            <div className="w-full max-w-80 flex-grow flex flex-col justify-between items-start p-2 gap-3">
                <div className="line-clamp-2 text-primary text-sm">{title}</div>


                {desc &&
                    <div
                        className="text-textSecondary line-clamp-2"
                        dangerouslySetInnerHTML={{ __html: desc }}
                    />
                    // <div className="line-clamp-2">{desc}</div>
                }
            </div>
        </div>
    )
}

export default NewsCard