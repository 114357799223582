import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../../Component/Loading/Loading";
import "react-quill/dist/quill.snow.css";
import {
  fetchSubmitEnquiry,
  removeEnquiryData,
} from "../../Redux/Enquiry/action";
import { toast } from "react-toastify";
import { removeCategoryData } from "../../Redux/Category/action";
import Details from "./Components/Details";
import Location from "./Components/Location";
import WhenStart from "./Components/WhenStart";
import { geocodeByAddress } from "react-google-places-autocomplete";
import Photo from "./Components/Photo";
import Dynamic from "./Components/Dynamic";
import Cookies from "js-cookie";
import DynamicSteps from './Components/DynamicSteps/DynamicSteps'


const FormField = ({ setToggle, catId }) => {


  const profileData = useSelector((state) => state.profiles.profile, shallowEqual)

  const [formData, setFormData] = useState({
    name: profileData ? `${profileData?.first_name} ${profileData?.last_name}` : "",
    phone: profileData?.contact_number ?? "",
    email: profileData?.user_email ?? ""
  });

  useEffect(() => {
    // console.log("ATTENTION!!! ENQUIRY FORM FIELD UPDATED!!!")

    if (profileData) {
      setFormData((prev) => {
        return {
          ...prev,
          name: `${profileData?.first_name} ${profileData?.last_name}`,
          phone: profileData?.contact_number ?? "",
          email: profileData?.user_email ?? ""
        };
      });
    }
  }, [profileData])




  const [location, setLocation] = useState("");
  const [components, setComponents] = useState({
    location: true,
    when_start: false,
    photo: false,
    dynamic: false,
    details: false,
  });
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.enquiry.isloading, shallowEqual);
  const formId = useSelector((state) => state.enquiry.form_id, shallowEqual);
  const dynamicFields = useSelector(
    (state) => state.enquiry.dynamic_field,
    shallowEqual
  );

  const message = useSelector((state) => state.enquiry.message, shallowEqual);
  const errMessage = useSelector((state) => state.enquiry.errMsg, shallowEqual);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangeTextEditor = (content) => {
    setFormData((prev) => {
      return {
        ...prev,
        description: content,
      };
    });
  };

  const getLocationData = async () => {
    if (location) {
      const search = location.label;
      const promises = await geocodeByAddress(search);

      const geocodes = await Promise.all(promises);
      const description = geocodes?.[0];

      // console.log("description", description);
      // property address
      setFormData((prev) => {
        return {
          ...prev,
          address: description?.formatted_address,
        };
      });

      // property state
      const state = description?.address_components.filter((k) =>
        k.types.includes("administrative_area_level_1")
      );

      state.map((val) => {
        return setFormData((prev) => {
          return {
            ...prev,
            state: val.short_name,
          };
        });
      });

      //postcode
      const postcode = description?.address_components.filter((k) =>
        k.types.includes("postal_code")
      );

      postcode.map((val) => {
        return setFormData((prev) => {
          return {
            ...prev,
            postcode: val.short_name,
          };
        });
      });

      // property Suburb
      const suburb = description?.address_components.filter((k) =>
        k.types.includes(
          "locality" || "political" || "administrative_area_level_2"
        )
      );

      suburb.map((val) => {
        return setFormData((prev) => {
          return {
            ...prev,
            suburb: val.long_name,
          };
        });
      });
    }
  };

  const handleSubmit = () => {
    // console.log(formData)
    dispatch(fetchSubmitEnquiry(formData, token));
  };

  useEffect(() => {
    catId &&
      setFormData((prev) => {
        return {
          ...prev,
          lead_cat_id: catId,
        };
      });
  }, [catId]);

  useEffect(() => {
    formId &&
      setFormData((prev) => {
        return {
          ...prev,
          form_id: formId,
        };
      });
  }, [formId]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeCategoryData());
    message && dispatch(removeEnquiryData());
    message && window.location.reload(false);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
  }, [errMessage]);






  return (
    <div className="w-full">
      <h6 className="font-semibold text-2xl">
        Enquiry <span className="text-primary">Details</span>
      </h6>
      <p className="text-base mt-2">
        please fill out the form to make an enquiry{" "}
      </p>

      {loading ? (
        <div className="w-full h-full mt-5">
          <Loading />
        </div>
      ) : (
        <>
          <div className="w-full border rounded-xl p-4 mt-10 flex items-center justify-between">
            <h5 className="font-medium text-lg">
              Form Id: <span className="text-primary">#{formId}</span>
            </h5>
            <p className="text-base  font-medium">
              Category:{" "}
              <span className="text-primary"> {dynamicFields.title}</span>
            </p>
          </div>

          {components.location && (
            <Location
              location={location}
              setLocation={setLocation}
              setToggle={setToggle}
              setComponents={setComponents}
              getLocationData={getLocationData}
            />
          )}

          {components.when_start && (
            <WhenStart
              formData={formData}
              setFormData={setFormData}
              setComponents={setComponents}
              setLocation={setLocation}
            />
          )}

          {components.photo && (
            <Photo
              setComponents={setComponents}
              formData={formData}
              setFormData={setFormData}
            />
          )}

          {components.dynamic && (
            <DynamicSteps
              setComponents={setComponents}
              setFormData={setFormData}
              formData={formData}
            />
          )}
          {/* {components.dynamic && (
            <Dynamic
              setComponents={setComponents}
              setFormData={setFormData}
              formData={formData}
            />
          )} */}

          {components.details && (
            <Details
              formData={formData}
              handleChange={handleChange}
              handleChangeTextEditor={handleChangeTextEditor}
              handleSubmit={handleSubmit}
              setComponents={setComponents}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FormField;
