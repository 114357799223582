import React from "react";
import { classiBazarLogo } from "../../Asset/Assest";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loading from "../Loading/Loading";
import { scrollToTop } from "../NavigateTop";
import { fetchGetNotification } from "../../Redux/Notification/action";
import Button from "../Button/Button";


const Notification = ({ notification, setPage, page }) => {


  const dispatch = useDispatch()
  const token = Cookies.get("token");

  const nextPage = useSelector(state => state.notification.has_next_page)
  const isloading = useSelector(state => state.notification.isloading)
  const currPage = useSelector(state => state.notification.currentPage)

  const handlePrevData = () => {
    scrollToTop()
    setPage(page - 1)
    dispatch(fetchGetNotification(page - 1, token));
  }

  const handleNextData = () => {
    scrollToTop()
    setPage(page + 1)
    dispatch(fetchGetNotification(page + 1, token));
  }


  return (
    <div className="hidden lg:block w-1/3 shadow-md p-3 rounded-xl bg-white z-40 border h-80 overflow-y-auto absolute top-24 right-0 transition duration-1000 ease-in-out">
      <h5 className="font-medium text-base">Notifications</h5>
      <hr className="w-full mt-2" />


      {isloading ? (
        <div className="w-full bg-white h-screen">
          <Loading />
        </div>
      ) : (
        notification && notification.length ? (
          notification.map((val) => {
            return (
              <div
                className="flex mt-5 gap-x-5 w-full cursor-pointer hover:shadow-md overflow-hidden  border rounded-lg p-2"
                key={val.id}
              >
                <div className="w-10 h-10">
                  <img
                    src={classiBazarLogo}
                    alt=""
                    className="w-full h-full rounded-full"
                  />
                </div>

                <div className="w-full overflow-hidden">
                  <h6 className="text-base font-medium truncate">{val.title}</h6>
                  <p className="text-textSecondary mt-2 text-sm">{val.body}</p>
                </div>
              </div>
            );
          })
        ) : (
          <h5 className="flex justify-center items-center w-full h-full">
            No Notification Yet
          </h5>
        )
      )}

      <div className="flex mt-5 gap-x-5 w-full cursor-pointer hover:shadow-md overflow-hidden  border rounded-lg p-2">
        {/* {console.log(currPage)} */}
        {currPage > 1 &&
          <div>
            <Button
              value="Prev"
              className="bg-gray-50 text-black rounded-xl border py-2 px-5"
              handleClick={handlePrevData}
            />
          </div>
        }
        {nextPage &&
          <div className="ml-auto">
            <Button
              value="Next"
              className="bg-gray-50 text-black rounded-xl border py-2 px-5"
              handleClick={handleNextData}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default Notification;
