import React from "react";
import { Button, NormalInput, NormalTextArea } from "../../../Component";
import { shallowEqual, useSelector } from "react-redux";


const Details = ({ formData, handleChange, handleSubmit, setComponents }) => {

  const dynamicField = useSelector(
    (state) => state.enquiry.dynamic_field,
    shallowEqual
  );

  const handleBack = () => {
    if (dynamicField?.fields === undefined || dynamicField?.fields === null) {
      setComponents({
        location: false,
        when_start: false,
        photo: true,
        dynamic: false,
        details: false,
      });
    } else {
      if (Object.keys(dynamicField?.fields)?.length === 0) {
        console.log("inside");
        setComponents({
          location: false,
          when_start: false,
          photo: true,
          dynamic: false,
          details: false,
        });
      } else {
        console.log("outside");
        // const [currentStep, setCurrentStep] = useLocalStorage('currentStep', 1);
        localStorage.setItem("currentStep", Object.keys(dynamicField?.fields)?.length)
        setComponents({
          location: false,
          when_start: false,
          photo: false,
          dynamic: true,
          details: false,
        });
      }
    }
  }

  return (
    <div className="w-full mt-10">
      <div className="w-full border rounded-xl p-5">
        <p className="font-semibold text-xl">Personal Detail</p>
        <div className="w-full flex items-center justify-between gap-x-5 mt-5">
          <div className="w-full">
            <NormalInput
              label="Name"
              name="name"
              value={formData?.name}
              handleChange={handleChange}
              placeholder="Your Name"
              required={true}
            />
          </div>
          <div className="w-full">
            <NormalInput
              label="Email Address"
              name="email"
              value={formData?.email}
              handleChange={handleChange}
              placeholder="Your Email"
              required={true}
            />
          </div>
        </div>

        <div className="w-1/2 mt-5">
          <NormalInput
            label="Phone"
            name="phone"
            value={formData?.phone}
            handleChange={handleChange}
            placeholder="Your Phone"
            required={true}
          />
        </div>

        <div className="w-full mt-5">
          <NormalTextArea
            label="Description"
            required={true}
            name="description"
            value={formData?.desprition}
            handleChange={handleChange}
            placeholder="Note..."
          />
        </div>
      </div>
      <div className="w-full mt-7 flex items-center gap-x-5 justify-end">
        <Button
          value="Back"
          handleClick={() =>
            handleBack()
          }
          className="w-1/5 border rounded-xl py-3 bg-gray-50"
        />

        <Button
          value="Submit"
          handleClick={handleSubmit}
          className="w-1/5 bg-primary text-white rounded-xl py-3"
        />
      </div>
    </div>
  );
};

export default Details;
