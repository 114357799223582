import React from "react";
import { NavLink } from "react-router-dom";

const menu = [
  {
    id: 1,
    name: "Profile",
    link: "/me",
  },

  // {
  //   id: 2,
  //   name: "My Draft",
  //   link: "/me/draft",
  // },

  {
    id: 4,
    name: "My Enquiries",
    link: "/me/enquiries",
  },

  // {
  //   id: 7,
  //   name: "Notification Setting",
  //   link: "/me/notification-settings",
  // },
];
const AccountHeader = () => {
  return (
    <div className="border w-full px-3 py-5 rounded-lg flex flex-col gap-y-2 bg-white">
      {menu.map((val) => {
        return (
          <NavLink
            key={val.id}
            end
            to={val.link}
            className={({ isActive, isPending }) =>
              isActive
                ? " text-white rounded-xl text-base bg-primary py-3 px-5 font-medium"
                : "text-sm py-3 text-textSecondary px-5 hover:text-textPrimary"
            }
          >
            {val.name}
          </NavLink>
        );
      })}
    </div>
  );
};

export default AccountHeader;
