import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';


function SampleNextArrow(props) {
    const { className, style, onClick, } = props;
    return (
        <div
            className={`${className}`}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "fit-content",
                width: "60px",
                transform: "translate(100%, -50%)",
                right: 0,
            }}
            onClick={onClick}
        >
            <div className='group aspect-square rounded-full w-full relative bg-secondary hover:bg-[#AFAFAF] duration-300 block'>
                <BsChevronRight className='group-hover:text-white text-black duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className}`}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "fit-content",
                width: "60px",
                transform: "translate(-100%, -50%)",
                left: 0,
            }}
            onClick={onClick}
        >
            <div className='group aspect-square rounded-full w-full relative bg-secondary hover:bg-[#AFAFAF] duration-300 block'>
                <BsChevronLeft className='group-hover:text-white text-black duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>
    );
}


function ItemsCarousel({
    contents,
    className,
    slidesToShow = 4
}) {

    const limitSlides = (num) => {
        return Math.max(slidesToShow - 1 < num ? slidesToShow - 1 : num, 1)
    }

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        speed: 500,
        slidesToShow: limitSlides(4),
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    arrows: false,
                    dots: true,
                    swipeToSlide: true,
                    slidesToShow: limitSlides(3)
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: limitSlides(2),
                    swipeToSlide: true,
                    arrows: false,
                    dots: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: limitSlides(2),
                    swipeToSlide: true,
                }
            }
        ]
    };



    const disable = useRef(false)
    const [isMouseMoving, setIsMouseMoving] = useState(true);
    const [isMouseDown, setIsMouseDown] = useState(false)

    useEffect(() => {
        if (isMouseDown && isMouseMoving) { disable.current = true }
        else if (!isMouseDown) disable.current = false

    }, [isMouseDown, isMouseMoving])


    const handleMouseMove = () => {
        if (isMouseDown) {
            setIsMouseMoving(true);
        }

        const timeoutId = setTimeout(() => {
            setIsMouseMoving(false)
        }, 500);
        return () => clearTimeout(timeoutId);
    };

    const handleMouseDown = () => {
        setIsMouseDown(true)
    }

    const condition = !isMouseMoving && isMouseDown && !disable.current

    return (
        <>
            <style className='hidden'>
                {`
                .slick-arrow::before {
                    content: none;
                }
                .slick-dots {
                    position: static;
                }
                `}
            </style>

            <Slider {...settings} className={`relative`}>
                {contents?.map((content, index) => (
                    <div key={index} className={`${className}`}>
                        {React.cloneElement(content, {
                            isMouseMoving,
                            handleMouseDown,
                            handleMouseMove,
                            setIsMouseDown,
                            setIsMouseMoving,
                            isMouseDown,
                            condition
                        })}
                    </div>
                ))}
            </Slider>
        </>
    );
}

export default ItemsCarousel;
