import React, { useEffect, useState } from "react";
import { logo } from "../../Asset/Assest";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../Component";
import { fetchSignup, removeAuthData } from "../../Redux/Auth/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading/Loading";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const message = useSelector((state) => state.auth.message, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   initial values
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
    email: Yup.string().required("Required*"),
    password: Yup.string().required("Required*").min(8),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    localStorage.setItem("email", values.email);

    dispatch(fetchSignup(values));
    setInProgress(true);

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && navigate("/auth/verify");
    message && setInProgress(false);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setInProgress(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-12 w-full">
      <div className="w-11/12 sm:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto border bg-white shadow-md p-5 sm:p-7 sm:mt-10 rounded-xl">
        <div className="flex justify-center items-center flex-col">
          <div className="w-1/2 sm:w-5/12 h-8 sm:h-12 xl:h-9">
            <img src={logo} alt="" className="w-full h-full object-contain" />
          </div>
          <h4 className="font-semibold text-textPrimary text-2xl mt-6">
            Create an Account
          </h4>
        </div>

        <div className="mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(fornik) => (
              <Form>
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    control="input"
                    name="first_name"
                    placeholder="Enter Your First Name"
                  />
                </div>

                <div className="w-full mt-5">
                  <FormikControl
                    label="Last Name"
                    control="input"
                    name="last_name"
                    placeholder="Enter Your Last Name"
                  />
                </div>

                <div className="w-full mt-5">
                  <FormikControl
                    label="Email"
                    control="input"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="w-full mt-5">
                  <FormikControl
                    label="Password"
                    control="password"
                    name="password"
                    placeholder="Enter Your Password"
                    type={`${showPassword ? "text" : "password"}`}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                </div>
                <div className="w-full mt-7">
                  <Button
                    value="Create Account"
                    type="submit"
                    className="bg-primary py-3.5 w-full rounded-xl text-white"
                  />
                </div>
              </Form>
            )}
          </Formik>

          <p className="text-center text-lg text-textSecondary mt-7">
            Already have an account?{" "}
            <Link to="/auth/sign-in">
              <span className="text-primary font-semibold cursor-pointer hover:underline">
                Sign in
              </span>
            </Link>
          </p>

          <p className="font-medium text-lg text-textSecondary mt-10 text-center">
            By proceeding, you agree to the{" "}
            <span className="text-green-600 cursor-pointer hover:underline">
              <Link to="/terms-of-use">Terms & Conditions </Link>
            </span>
            and{" "}
            <span className="text-green-600 cursor-pointer hover:underline">
              <Link to="/privacy-policy">Privacy Policies </Link>
            </span>
          </p>
          <div className="flex items-center justify-center gap-x-3 w-3/4 mx-auto mt-10 text-textSecondary">
            <p className="cursor-pointer hover:underline">
              <Link to="/terms-of-use">Terms</Link>
            </p>
            <div className="border-r-2 h-6 text-textSecondary"></div>
            <p className="cursor-pointer hover:underline">
              <Link to="/privacy-policy">Privacy</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
