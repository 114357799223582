import React from 'react'
import { IoMdClose } from "react-icons/io";

const Modal = ({
    closeModal,
    title,
    contents,
    className,
}) => {

    return (
        <div
            className={`${className} overflow-y-auto max-h-[95%] border rounded divide-y z-[55] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white text-black w-screen md:w-auto min-w[200px] min-w-[80%]`}
        >
            <div className='px-6 py-4 flex justify-between items-center '>
                <div className='text-xl font-medium'>{title}</div>
                <IoMdClose onClick={() => closeModal()} className='cursor-pointer ml-auto text-2xl' />
            </div>

            <div className='px-6 py-4'>
                {contents}
            </div>

        </div>
    )
}

export default Modal