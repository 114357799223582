import React, { useEffect, useMemo, useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetDirectories,
  fetchGetDirectorySuggestions,
} from "../../Redux/Directory/action";
import Loading from "../../Component/Loading/Loading";
import debounce from "lodash.debounce";
import { fetchGetLeadForm } from "../../Redux/Enquiry/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { directory } from "../../Asset/Assest"
import { Button } from "../../Component";

const Directory = ({ setCatId, setToggle }) => {
  const [keyword, setKeyword] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.directory.isloading,
    shallowEqual
  );
  const directories = useSelector(
    (state) => state.directory.directory,
    shallowEqual
  );

  const suggestions = useSelector(
    (state) => state.directory.suggestions,
    shallowEqual
  );

  console.log('dir', directories)
  console.log('sigg', suggestions)

  // const handleChange = (e) => {
  //   setKeyword(e.target.value);
  //   setShowSuggestion(true);
  // };

  const onchangeSearchHandler = (e) => {
    setKeyword(e.target.value);
    setShowSuggestion(true);
  };
  // console.log(keyword)

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });


  const handleClickNext = (cat_id) => {
    scrollToTop()
    setCatId(cat_id)

    if (cat_id) {
      setToggle({
        start: false,
        category: false,
        field: true,
      });

      dispatch(fetchGetLeadForm(cat_id));
      navigate("/")
    } else {
      toast.error("Please Select Category");
    }
  };




  useEffect(() => {
    dispatch(fetchGetDirectorySuggestions(keyword));
  }, [keyword]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetDirectories());
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }



  // const test = directories.map((val) => {
  //   if(val.cat_name.chatat(0) )
  // })


  const subCatArr = [
    {
      "id": 0,
      "subcategory": "Abrasive and Blasting Services",
      "category": [
        { "cat_id": "6", "cat_name": "Abrasive  " },
        { "cat_id": "11", "cat_name": "Abrasive Blasting" },
        { "cat_id": "12", "cat_name": "Accountants  " },
        { "cat_id": "13", "cat_name": "Acrylic Rendering  " },
        { "cat_id": "14", "cat_name": "Agricultural " }
      ]
    },
    {
      "id": 1,
      "subcategory": "Air Conditioning Services",
      "category": [
        { "cat_id": "15", "cat_name": "Air Conditioning  " },
        { "cat_id": "16", "cat_name": "Air Conditioning Installations  " },
        { "cat_id": "17", "cat_name": "Air Conditioning Repairs  " },
        { "cat_id": "18", "cat_name": "Air Conditioning Spare Parts " },
        { "cat_id": "20", "cat_name": "Aluminium Fencing  " },
        { "cat_id": "19", "cat_name": "Aluminum Fabricators  " },
        { "cat_id": "21", "cat_name": "Annual Report Design  " },
        { "cat_id": "22", "cat_name": "Antennas  " },
        { "cat_id": "23", "cat_name": "Appliance Installation  " }
      ]
    },
    {
      "id": 2,
      "subcategory": "Automotive Services",
      "category": [
        { "cat_id": "24", "cat_name": "Appliance Repairs  " },
        { "cat_id": "25", "cat_name": "Appliance Servicing  " },
        { "cat_id": "26", "cat_name": "Arborists  " },
        { "cat_id": "27", "cat_name": "Architects  " },
        { "cat_id": "28", "cat_name": "Artificial Turf  " },
        { "cat_id": "423", "cat_name": "Asbestos Inspection " },
        { "cat_id": "29", "cat_name": "Asbestos Removal  " },
        { "cat_id": "30", "cat_name": "Asphalt & Bitumen " },
        { "cat_id": "31", "cat_name": "Auto Glass  " },
        { "cat_id": "32", "cat_name": "Auto Mechanic  " }
      ]
    },
    {
      "id": 3,
      "subcategory": "Building and Construction",
      "category": [
        { "cat_id": "33", "cat_name": "Auto Spray-paint  " },
        { "cat_id": "34", "cat_name": "Automotive Engineering " },
        { "cat_id": "35", "cat_name": "Awning Suppliers " },
        { "cat_id": "36", "cat_name": "Baby Sitting  " },
        { "cat_id": "37", "cat_name": "Balustrading  " }
      ]
    },
    {
      "id": 4,
      "subcategory": "Automotive and Mechanical Services",
      "category": [
        { "cat_id": "61", "cat_name": "Bamboo Flooring " },
        { "cat_id": "38", "cat_name": "Bath & Basin Resurface  " },
        { "cat_id": "62", "cat_name": "Bath Resurfacing " },
        { "cat_id": "39", "cat_name": "Bathroom Accessories  " },
        { "cat_id": "40", "cat_name": "Bathroom Renovations  " },
        { "cat_id": "41", "cat_name": "Bin Hire  " },
        { "cat_id": "42", "cat_name": "Blind Suppliers & Accessories  " },
        { "cat_id": "43", "cat_name": "Blocked Drains  " },
        { "cat_id": "44", "cat_name": "Bobcat Hire  " },
        { "cat_id": "45", "cat_name": "Bond Cleaning  " }
      ]
    },
    {
      "id": 5,
      "subcategory": "Home Improvement and Maintenance",
      "category": [
        { "cat_id": "46", "cat_name": "Bond Cleaning  " },
        { "cat_id": "47", "cat_name": "Bookkeepers  " },
        { "cat_id": "48", "cat_name": "Boring & Drilling  " },
        { "cat_id": "49", "cat_name": "Brakes & Rotors" },
        { "cat_id": "50", "cat_name": "Brick Equipment" },
        { "cat_id": "51", "cat_name": "Bricklayers" },
        { "cat_id": "52", "cat_name": "Builders  " },
        { "cat_id": "53", "cat_name": "Builders Clean  " },
        { "cat_id": "63", "cat_name": "Building Certifiers " },
        { "cat_id": "54", "cat_name": "Building Consultants  " }
      ]
    }
  ]



  return (
    <div className="w-full h-full mt-10 pb-10">
      <h6 className="text-2xl font-medium w-3/4 mx-auto"> Browse Directory</h6>
      <div className="w-full bg-primary p-5 mt-10">
        <div className="w-11/12 xl:w-3/4 mx-auto">
          <h6 className="text-xl text-white font-medium">
            What are you looking for?
          </h6>

          <div className="relative mt-10 w-full md:w-1/2">
            <div className="w-full bg-white border rounded-xl py-2.5 px-2 gap-x-2 flex items-center">
              <input
                type="text"
                className="py-1 w-full outline-none px-3"
                // className="w-1/2 mt-5 py-4 outline-none px-5 border rounded-xl text-gray-500"
                placeholder="I'm looking for..."
                // onChange={(e) => handleChange(e)}
                onChange={(e) => debouncedResults(e)}
              />
            </div>


            {keyword ?
              <div className="max-h-52 overflow-y-auto absolute z-10 bg-white top-full w-full border">
                {loading ? (
                  <div className="w-full h-full mt-5">
                    <Loading />
                  </div>
                ) : (
                  <div>

                    {suggestions?.map((val, index) => (

                      <div
                        key={index}
                        className="py-2 px-4 border-b cursor-pointer hover:bg-gray-50"
                        onClick={() => { handleClickNext(val.cat_id) }}
                      >
                        {val.cat_name}
                      </div>
                    ))}
                  </div>
                )}

              </div>
              :
              <></>
            }
          </div>

        </div>
      </div>

      <div className="p-5 w-11/12 xl:w-3/4  mx-auto">

        {/* <div className="w-full">
          <img src={directory} className="w-full h-full object-contain" alt="" />
        </div> */}



        <div
          className='space-y-6 p-4 min-h-[450px]'
          style={{
            WebkitColumns: '154px 3',
            MozColumns: '154px 3',
            columns: '154px 3',
            WebkitColumnGap: "0.75rem",
            MozColumnGap: "0.75rem",
            columnGap: "0.75rem",
          }}>
          {subCatArr?.map((subCat, index) => (
            <div
              key={index}
              className='h-fit w-full break-inside-avoid'
            >

              {typeof (subCat) === "string" ?
                <>
                  <div className='font-semibold text-sm'>{subCat}</div>
                </>
                :

                typeof (subCat) === "object" ?
                  <div className=''>
                    <div className='font-semibold text-lg'>{subCat.subcategory}</div>

                    <div className='mt-2'>
                      {subCat.category?.map((cat, index) => (
                        <div
                          key={index}
                        >
                          <Button
                            value={cat.cat_name}
                            className='text-start text-sm text-[#70747D] hover:underline'
                            handleClick={() => { handleClickNext(cat.cat_id) }}

                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  :
                  null
              }
            </div>
          ))}
        </div>



      </div>

    </div>
  );
};

export default Directory;
