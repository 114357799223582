import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  notification: [],
  unSeen: "",
  has_next_page: "",
  currentPage: null
};

export const Notification = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_NOTIICATION:
      return {
        ...state,
        isloading: false,
        success: true,
        notification: action.payload.notifications,
        unSeen: action.payload.no_of_unseen,
        has_next_page: action.payload.has_next_page,
        currentPage: action.payload.current_page
      };

    case ActionTypes.MAKE_NOTIFICATION_SEEN:
      return {
        ...state,
        isloading: false,
        success: true,
      };

    case ActionTypes.REMOVE_NOTIFICATIOn:
      return {
        isloading: true,
        success: false,
        notification: [],
        unSeen: "",
        has_next_page: "",
        currentPage: null
      };

    default:
      return state;
  }
};
