import React, { useState } from "react";
import CategoryComponent from "./CategoryComponent";
import StartComponent from "./StartComponent";
import FormField from "./FormField";

const LeadEnquiry = ({ toggle, setToggle, catId, setCatId }) => {
  // const [toggle, setToggle] = useState({
  //   start: false,
  //   category: true,
  //   field: false,
  // });

  // const [catId, setCatId] = useState("");

  return (
    <div className="w-full h-full">
      <div>
        {/* {toggle.start && <StartComponent setToggle={setToggle} />} */}
        {toggle.category && (
          <CategoryComponent
            setToggle={setToggle}
            catId={catId}
            setCatId={setCatId}
          />
        )}
        <div className="w-11/12 xl:w-3/4 mx-auto py-10 ">
          {toggle.field && <FormField setToggle={setToggle} catId={catId} />}
        </div>
      </div>
    </div>
  );
};

export default LeadEnquiry;
