import React, { useEffect } from "react";
import Header from "./Header";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import testData from './testData.json'
import { classiBazarLogo } from '../../Asset/Assest'
import { fetchGetAllNewsData, removeNewsData } from "../../Redux/News/action";
import { useNavigate } from "react-router-dom"

const BlogPage = () => {

    const blogs = useSelector((state) => state.news.news, shallowEqual);
    const loading = useSelector((state) => state.news.isloading, shallowEqual);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    console.log(loading);
    console.log(blogs);

    useEffect(() => {
        scrollToTop();
        dispatch(fetchGetAllNewsData());
        return () => {
            dispatch(removeNewsData());
        };
    }, [dispatch]);


    const handleOnClick = (id) => {
        scrollToTop();
        navigate(`/blog/${id}`)
    }

    return (
        <div className="w-11/12 pb-20 xl:w-3/4 mx-auto space-y-12">
            <div className="mt-10">
                <Header news={blogs} />
            </div>

            <div className="space-y-8">
                <h4 className="text-2xl font-semibold">ClassiLeads</h4>

                <div className="grid gap-4 grid-cols-[repeat(auto-fill,minmax(200px,1fr))] ">
                    {blogs?.blogs?.map((val) => (
                        <div
                            onClick={() => handleOnClick(val.id)}
                            className={`mr-auto h-full group duration-300 md:min-h-[18.25rem] border overflow-hidden w-fit rounded-lg flex flex-col justify-stretch items-center cursor-pointer gap-y-4`}
                        >

                            <div className="w-full overflow-hidden">
                                <div className="w-full aspect-[10/12]">
                                    <img src={val.picture} className="group-hover:scale-125 duration-200 w-full h-full object-contain" alt={""} />
                                </div>
                            </div>


                            <div className="w-full flex-grow flex flex-col justify-between items-start p-2 gap-3">
                                <div className="line-clamp-2 text-primary text-sm">{val.title}</div>

                                {val.content && <div className="line-clamp-2">{val.content}</div>}
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            {/* <div className="space-y-20">
                {news?.categories?.map((data) => {
                    const catFilteredBlogs = news.blogs?.filter((blog) => blog.category === "Classileads")
                    return (
                        <div key={data.id} className="space-y-8">
                            <h4 className="text-2xl font-semibold">{data.title}</h4>

                            <div className="grid gap-4 grid-cols-[repeat(auto-fill,minmax(200px,1fr))] ">
                                {catFilteredBlogs?.map((val) => (
                                    <div key={val.id}>
                                        <NewsCard
                                            id={val.id}
                                            image={val.picture}
                                            title={val.title}
                                            desc={val.content}
                                            executeFunctions={handleOnClick}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div> */}

            {/* {news.blogs &&
                news.blogs.map((data) => (
                    <div key={data.id} className="mt-16">
                        <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                            {data.title}
                        </h4>
                        <hr className="w-20 border border-primary mt-4" />

                        <div className="mt-10 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
                            <div className="rounded-xl border border-primary flex flex-col justify-between items-center">

                                <div className="h-2/3">
                                    <div className="w-40">
                                        <img src={data.picture} className="w-full h-full object-contain" alt="" />
                                    </div>
                                </div>
                                <div className="h-1/3 flex justify-center items-center">
                                    <div className="font-bold">{data.title}</div>


                                </div>
                            </div>
                        </div>
                    </div>
                ))
            } */}
        </div>
    );
};

export default BlogPage;