export const ENQUIRY_LOADING = "ENQUIRY_LOADING";
export const GET_LEAD_FORM_SUCCESS = "GET_LEAD_FORM_SUCCESS";
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const GET_LEAD_FORM_FAIL = "GET_LEAD_FROM _FAIL";
export const SUBMIT_ENQUIRY_SUCCESS = "SUBMIT_ENQUIRY_SUCCESS";
export const SUBMIT_ENQUIRY_FAIL = "SUBMIT_ENQUIRY_FAIL";
export const GET_MY_ENQUIRY = "GET_MY_ENQUIRY";
export const GET_MY_ENQUIRY_DETAIL = "GET_MY_ENQUIRY_DETAIL";
export const REMOVE_ENQUIRY_DATA = "REMOVE_ENQUIRY_DATA";
export const GET_MY_HIRED_ENQUIRY = "GET_MY_HIRED_ENQUIRY";
