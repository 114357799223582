import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
    type: ActionTypes.INBOX_LOADING,
});

export const removeInboxData = () => ({
    type: ActionTypes.REMOVE_INBOX_DATA,
});

// export const fetchGetAllOfferMessages = (page, token) => async (dispatch) => {
//     await axios
//         .get(`${BaseUrl}/offermessages_new?page=${page}`, {
//             headers: { Authorization: `Bearer ${token}` },
//         })
//         .then(({ data }) => {
//             dispatch({
//                 type: ActionTypes.GET_OFFER_MESSAGE,
//                 payload: data,
//             });
//         })
//         .catch((err) => {
//             console.error(err)
//         });
// };





export const fetchGetOfferListInPost = (id, token) => async (dispatch) => {
    await axios
        // .get(`${BaseUrl}/offerlists?post_id=${postId}`, {
        .get(`${BaseUrl}/agent_lead_enquiry_users/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.GET_OFFER_LIST_IN_POST,
                payload: data,
            });
        })
        .catch((err) => {
            console.error(err)
        });
};



// id === enq id
// userId === i_userId of /agent_lead_enquiry_users/{enqId} data
export const fetchGetChatList = (id, userId, token) => async (dispatch) => {
    await axios
        .get(
            // `${BaseUrl}/offers_detail?post_id=${postId}&offer_id=${offerId}`,
            `${BaseUrl}/agent_lead_enquiry_users/${id}/${userId}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        )
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.GET_CHAT_LIST,
                payload: data,
            });
        })
        .catch((err) => {
            console.error(err)
        });
};



// to send chat
export const postHireAgent = (id, token) => async (dispatch) => {
    // console.log(id, "action hire", token)
    await axios
        .post(`${BaseUrl}/hire_agent_for_lead_enquiry/${id}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.HIRE_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.HIRE_FAIL,
                payload: e.response?.data,
            });
        });
};


export const fetchSendMessage = (body, token) => async (dispatch) => {
    await axios
        .post(`${BaseUrl}/add_lead_enquiryChatMsg`, body, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.SEND_MESSAGE_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.SEND_MESSAGE_FAIL,
                payload: e?.response?.data,
            });
        });
};






// need endpoint update?
export const fetchSendFile = (body, token) => async (dispatch) => {
    await axios
        .post(`${BaseUrl}/upload_lead_image`, body, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.SEND_FILE_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.SEND_FILE_FAIL,
                payload: e?.response?.data,
            });
        });
};
