import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  directory: [],
  detail: "",
  suggestions: [],
};

export const Directory = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DIRECTORY_SUGGESTION:
      return {
        ...state,
        isloading: false,
        success: true,
        suggestions: action.payload,
      };

    case ActionTypes.GET_DIRECTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        directory: action.payload,
      };

    case ActionTypes.GET_DIRECTORY_DETAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        detail: action.payload,
      };

    case ActionTypes.REMOVE_DIRECTORY:
      return {
        isloading: true,
        success: false,
        directory: [],
        detail: "",
      };

    default:
      return state;
  }
};
