import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  images: [],
  message: "",
  errMsg: "",
};

export const Image = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        images: action.payload.source,
        message: action.payload.message,
      };

    case ActionTypes.UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_IMAGES_DATA:
      return {
        isloading: true,
        success: false,
        images: [],
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
