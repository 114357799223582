import React, { useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Button } from "../../../Component";

const Location = ({
  location,
  setLocation,
  setToggle,
  setComponents,
  getLocationData,
}) => {

  useEffect(() => {
    if (location) {
      getLocationData();
      setComponents({
        location: false,
        when_start: true,
        photo: false,
        dynamic: false,
        details: false,
      });
    }
  }, [location, getLocationData, setComponents])

  // const handleChangeLocation = (e) => {
  //   // console.log(e)
  //   setLocation(e);
  //   console.log(location)
  //   if (e) {
  //     console.log(location)
  //     getLocationData();

  //     setTimeout(() => {
  //       setComponents({
  //         location: false,
  //         when_start: true,
  //         photo: false,
  //         dynamic: false,
  //         details: false,
  //       });
  //     }, 500);
  //   }
  // }

  return (
    <div className="mt-10 w-full">
      <div className="p-5 border rounded-xl bg-white">
        <h4 className="text-textPrimary text-xl font-semibold">
          Where are you looking?
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="mt-10 bg-white w-full">
          <GooglePlacesAutocomplete
            placeholder="Where is it?"
            textInputProps={{
              placeholderTextColor: "#32a852",
              returnKeyType: "search",
            }}
            autocompletionRequest={{
              componentRestrictions: { country: ["au"] },
            }}
            selectProps={{
              location,
              onChange: (e) => {
                setLocation(e);
                // handleChangeLocation(e)
              },
              placeholder: "Enter your Preferred Location",
            }}
          />
          <p className="text-textPrimary text-sm mt-3 md:text-base">
            *You can add multiple locations as per required
          </p>
        </div>
        {location ? (
          <div className="mt-12 md:mt-16 w-full px-5 py-1 border-l-4 border-l-primary ">
            <p className="text-primary font-medium text-base md:text-lg">
              Awesome!{" "}
            </p>
            <p className="text-textPrimary text-sm md:text-base mt-2">
              Let’s make sure we get all the info we need, so we can help you
              get into your home sooner - an agent will be in touch to explain
              all your options.
            </p>
          </div>
        ) : null}
      </div>

      <div className="mt-5 flex items-center justify-end gap-x-5">
        <Button
          value="Back"
          handleClick={() => {
            setToggle({
              start: false,
              category: true,
              field: false,
            });
          }}
          className="w-1/5 bg-gray-50 rounded-xl border py-3"
        />
        {location && (
          <Button
            value="Next"
            handleClick={() => {
              getLocationData();
              setComponents({
                location: false,
                when_start: true,
                photo: false,
                dynamic: false,
                details: false,
              });
            }}
            className="w-1/5 bg-primary text-white rounded-xl py-3"
          />
        )}
      </div>
    </div>
  );
};

export default Location;
