import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigureStore } from "./Redux/configureStore";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = ConfigureStore();
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="660338474435-242ofq0e118rvlgrnkkpa3fn977ousdf.apps.googleusercontent.com">
      {/* <GoogleOAuthProvider clientId="973987325655-60mkmggav9odm0u94f7lnavbjt4tvd0i.apps.googleusercontent.com"> */}
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
