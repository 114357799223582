import React, { useEffect } from "react";
import { Button } from "../../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { RiImageAddFill } from "react-icons/ri";
import {
  fetchUploadImages,
  removeImagesData,
} from "../../../Redux/Images/action";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const Photo = ({ setComponents, setFormData, formData }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const dynamicField = useSelector(
    (state) => state.enquiry.dynamic_field,
    shallowEqual
  );

  const message = useSelector((state) => state.image.message, shallowEqual);
  const images = useSelector((state) => state.image.images, shallowEqual);
  const errMessage = useSelector((state) => state.image.errMsg, shallowEqual);
  console.log(images)
  const uploadImage = (e) => {
    if (e.target.files) {
      const fileType = Array.from(e.target.files);
      const formData = new FormData();
      fileType.forEach((img, i) => {
        formData.append(`files[]`, img);
      });
      dispatch(fetchUploadImages(formData, token));
    }
  };

  const handleClickNext = () => {
    localStorage.setItem("currentStep", 1)
    if (dynamicField?.fields === undefined || dynamicField?.fields === null) {
      setComponents({
        location: false,
        when_start: false,
        photo: false,
        dynamic: false,
        details: true,
      });
    } else {
      if (Object.keys(dynamicField?.fields)?.length === 0) {
        console.log("inside");
        setComponents({
          location: false,
          when_start: false,
          photo: false,
          dynamic: false,
          details: true,
        });
      } else {
        console.log("outside");
        setComponents({
          location: false,
          when_start: false,
          photo: false,
          dynamic: true,
          details: false,
        });
      }
    }
  };

  useEffect(() => {
    message &&
      images &&
      images.length &&
      setFormData((prevData) => {
        return {
          ...prevData,
          images: [
            ...(prevData["images"] || []),

            images.map((val) => {
              return {
                image: val.image_name,
                thumbnail: val.image_thumbnail_name,
              };
            }),
          ].flat(),
        };
      });
  }, [message && images]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeImagesData());
  }, [errMessage]);

  return (
    <div className="mt-10 w-full">
      <div className="p-5 border rounded-xl bg-white">
        <h4 className="text-textPrimary text-xl font-semibold">
          Add photo or Documents
        </h4>
        <hr className="w-20 border border-primary mt-4" />

        <div className="mt-5">
          <div className="flex w-full flex-wrap items-center gap-x-5">
            <div className="mt-5">
              <label>
                <div
                  title="upload image"
                  className="h-16 w-16 sm:h-20 xl:h-24 sm:w-20 xl:w-24 flex justify-center items-center cursor-pointer border-2 border-gray-300 bg-gray-200 hover:bg-gray-300"
                >
                  <RiImageAddFill className="text-4xl text-gray-600" />
                  <input
                    type="file"
                    multiple
                    accept="image/jpg, image/png, image/webp, image/jpeg"
                    onChange={(e) => uploadImage(e)}
                    className="hidden h-40"
                  />
                </div>
              </label>
            </div>
            {formData?.images?.map((val, i) => {
              return (
                <div
                  className="h-16 w-16 sm:h-20 xl:h-24 sm:w-20 xl:w-24 rounded-lg mt-5 relative"
                  key={i}
                >
                  {/* <div
                    className="absolute -right-1 -top-2 rounded-full bg-white border cursor-pointer hover:bg-red-500"
                    // onClick={() => handleDeleteImage(val.image_name)}
                  >
                    <MdDeleteForever className=" text-red-500 text-xl hover:text-white" />
                  </div> */}
                  <img
                    src={val.image}
                    alt=""
                    className="w-full h-full object-fill"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="mt-5 flex items-center justify-end gap-x-5">
        <Button
          value="Back"
          handleClick={() => {
            setComponents({
              location: false,
              when_start: true,
              photo: false,
              dynamic: false,
              details: false,
            });
          }}
          className="w-1/5 bg-gray-50 rounded-xl border py-3"
        />

        <Button
          value={`${formData?.images?.length ? "Next" : "Skip"}`}
          handleClick={handleClickNext}
          className="w-1/5 bg-primary text-white rounded-xl py-3"
        />
      </div>
    </div>
  );
};

export default Photo;
