import * as ActionTypes from "./actionTypes";

const initialState = {
    isloading: true,
    success: false,
    message: "",
    errMsg: "",
};

export const Contact = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SEND_CONTACT_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                message: action.payload.message,
            };

        case ActionTypes.SEND_CONTACT_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                errMsg: action.payload?.message,
            };

        case ActionTypes.SEND_QUERY_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                message: action.payload.message,
            };

        case ActionTypes.SEND_QUERY_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                errMsg: action.payload.message,
            };

        case ActionTypes.REMOVE_CONTACT:
            return {
                isloading: true,
                success: false,
                message: "",
                errMsg: "",
            };

        default:
            return state;
    }
};
