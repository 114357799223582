import React from "react";

const NormalInput = ({
  label,
  name,
  value,
  handleChange,
  placeholder,
  required,
}) => {
  return (
    <div className="text-sm mt-5">
      <div className="w-full flex items-center gap-x-5 pb-2">
        <label className="font-medium flex text-sm items-center gap-x-1 w-full">
          {label}
          {required ? <span className="text-red-500">*</span> : ""}
        </label>
      </div>

      <input
        type="text"
        name={name}
        required={required}
        value={value}
        onChange={handleChange}
        className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
        placeholder={placeholder}
      />
    </div>
  );
};

export default NormalInput;
