import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const enquiryLoading = () => ({
  type: ActionTypes.ENQUIRY_LOADING,
});

export const removeEnquiryData = () => ({
  type: ActionTypes.REMOVE_ENQUIRY_DATA,
});


// to get the form data like of plumber, carpenter etc
export const fetchGetLeadForm = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/lead_form?cat_id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_LEAD_FORM_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.GET_LEAD_FORM_FAIL,
        payload: e?.response?.data,
      });
    });
};



// To submit form data.
export const fetchSubmitEnquiry = (body, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/lead_request`,
      body,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SUBMIT_ENQUIRY_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SUBMIT_ENQUIRY_FAIL,
        payload: e?.response?.data,
      });
    });
};



// In my account page
export const fetchGetMyEnquiry = (token, page = 1) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/lead_request?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_ENQUIRY,
        payload: data,
      });
    })
    .catch((err) => {
      console.error(err)
    })
};


// hired enquiries
export const fetchGetMyHiredEnquiry = (token, page = 1, status = "Pending") => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/lead_request?page=${page}&status=${status}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_HIRED_ENQUIRY,
        payload: data,
      });
    })
    .catch((err) => {
      console.error(err)
    })
};


// To get the agents companies. (something related to hiring stuffs)
export const fetchGetMyEnquiryDetail = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/lead_request/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_ENQUIRY_DETAIL,
        payload: data,
      });
    })
    .catch((err) => {
      console.error(err)
    })
};


// 
export const fetchCompanyDetails = (enquiryId, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/agent_lead_enquiry_users/${enquiryId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_COMPANY_DETAIL,
        payload: data,
      });
    })
    .catch((err) => {
      console.error(err)
    })
};