import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const imagesLoading = () => ({
  type: ActionTypes.IMAGES_LOADING,
});

export const removeImagesData = () => ({
  type: ActionTypes.REMOVE_IMAGES_DATA,
});

export const fetchUploadImages = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/upload_lead_image`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPLOAD_IMAGE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPLOAD_IMAGE_FAIL,
        payload: e?.response?.data,
      });
    });
};
