import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "../../../../Component";
import { toast } from "react-toastify";
import useLocalStorage from "../../../../hooks/useLocalStorage";


const Questionnaire = ({ setComponents, setFormData, formData }) => {

    const fieldData = useSelector(
        (state) => state.enquiry.dynamic_field,
        shallowEqual
    );
    const questions = fieldData?.fields
    // console.log(questions)

    const [currentStep, setCurrentStep] = useLocalStorage('currentStep', 1);
    // const [currentStep, setCurrentStep] = useState(1);


    const handleAnswerSelect = (step, value, key) => {
        // setAnswers({
        //     ...answers,
        //     [step]: value
        // });
        setFormData((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        });

        if (step === Object.keys(questions).length) {
            // console.log(step)
            setComponents({
                location: false,
                when_start: false,
                photo: false,
                dynamic: false,
                details: true,
            });
        } else {
            setCurrentStep(step + 1);
        }

    };


    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <div>
            {currentStep <= Object.keys(questions).length && (
                <Question
                    step={currentStep}
                    question={questions[currentStep]}
                    onSelect={handleAnswerSelect}
                    previousStep={handlePreviousStep}
                    // selectedAnswer={formData[currentStep]}
                    setComponents={setComponents}
                />
            )}
        </div>
    );
};




const Question = ({ step, question, onSelect, previousStep, setComponents }) => {

    const handleAnswerSelection = (value) => {
        onSelect(step, value, question.attrs.label);
    };

    return (
        <div className="mt-10">
            <h3>{question.attrs.label}</h3>

            <div>
                {/* {console.log(question.attrs)} */}
                {console.log(question)}
                {question.attrs.type === "checkbox" ? (
                    <ul className="w-full grid grid-cols-4 gap-x-5 mt-5">
                        {Object.keys(question.childs).map((childKey) => (
                            <li key={childKey}>
                                <h6
                                    className={`border rounded-xl px-4 py-3 text-center cursor-pointer mt-3 `}
                                    onClick={() => { handleAnswerSelection(question.childs[childKey].value) }}
                                >
                                    {question.childs[childKey].label}
                                </h6>
                            </li>
                        ))}
                    </ul>
                ) : (
                    // when there's no option (no childs present: backend problem) then as a safety mechanism
                    <h6
                        className={`border w-fit rounded-xl px-4 py-3 text-center cursor-pointer mt-3 `}
                        onClick={() => { handleAnswerSelection("") }}
                    >
                        Default
                    </h6>
                )}
            </div>
            <div className="w-full flex items-center justify-end mt-5">
                {step > 1 ? (
                    <Button
                        value="Back"
                        className="w-1/5 bg-gray-50 rounded-xl border py-3"
                        handleClick={previousStep}
                    />
                ) : (
                    <>
                        <Button
                            value="Back"
                            handleClick={() => {
                                setComponents({
                                    location: false,
                                    when_start: false,
                                    photo: true,
                                    dynamic: false,
                                    details: false,
                                });
                            }}
                            className="w-1/5 bg-gray-50 rounded-xl border py-3"
                        />
                    </>
                )}
            </div>

        </div>
    );
};

export default Questionnaire;
