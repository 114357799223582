import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeContactData = () => ({
    type: ActionTypes.REMOVE_CONTACT,
});

export const fetchSendContact = (body) => async (dispatch) => {
    await axios
        .post(`https://classibazaar.com.au/cbazaar_api/api/deal/add_contact`, body)
        // .post(`${BaseUrl}/deal/add_contact`, body)
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.SEND_CONTACT_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.SEND_CONTACT_FAIL,
                payload: e.response?.data,
            });
        });
};

export const fetchAdvertise = (body) => async (dispatch) => {
    await axios
        .post(`${BaseUrl}/advertise_with_us`, body)
        .then(({ data }) => {
            dispatch({
                type: ActionTypes.SEND_QUERY_SUCCESS,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: ActionTypes.SEND_QUERY_FAIL,
                payload: e.response.data,
            });
        });
};
