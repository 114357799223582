import * as ActionTypes from "./actionTypes";

const initialState = {
    isloading: true,
    success: false,
    message: "",
    errMsg: "",
    offerMessages: [],
    hire: "",
    replies: [],
    chatList: [],
    totalData: "",
    file: "",
};

export const Inbox = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_OFFER_MESSAGE:
            return {
                ...state,
                isloading: false,
                success: true,
                offerMessages: action.payload.offers,
                totalData: action.payload.total_numbers,
            };

        case ActionTypes.GET_OFFER_LIST_IN_POST:
            return {
                ...state,
                isloading: false,
                success: true,
                replies: action.payload,
            };

        case ActionTypes.GET_CHAT_LIST:
            return {
                ...state,
                isloading: false,
                success: true,
                chatList: action.payload,
            };

        case ActionTypes.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                message: action.payload.message,
            };

        case ActionTypes.HIRE_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                hire: action.payload.status,
            };

        case ActionTypes.HIRE_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                hire: action.payload,
            };

        case ActionTypes.SEND_MESSAGE_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                errMsg: action.payload.message,
            };

        case ActionTypes.SEND_FILE_SUCCESS:
            return {
                ...state,
                isloading: false,
                success: true,
                file: action.payload.source,
            };

        case ActionTypes.SEND_FILE_FAIL:
            return {
                ...state,
                isloading: false,
                success: false,
                errMsg: action.payload?.message,
            };

        case ActionTypes.REMOVE_INBOX_DATA:
            return {
                isloading: true,
                success: false,
                message: "",
                errMsg: "",
                offerMessages: [],
                totalData: "",
                hire: "",
                replies: [],
                chatList: [],
                file: "",
            };

        default:
            return state;
    }
};
