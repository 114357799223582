import React from "react";

const NormalTextArea = ({
  name,
  label,
  required,
  placeholder,
  handleChange,
  value,
}) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
      >
        {label}
        {required ? <span className="text-red-500">*</span> : ""}
      </label>
      <textarea
        onChange={handleChange}
        name={name}
        value={value}
        className="border border-gray-300 w-full p-3 h-40 text-sm rounded-lg outline-gray-300"
        placeholder={placeholder}
      ></textarea>
    </div>
  );
};

export default NormalTextArea;
