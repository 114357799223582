import React from "react";
import { appStore, playStore } from "../../Asset/Assest";
import { FaFacebookF, FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { scrollToTop } from '../NavigateTop'
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchGetLeadForm } from "../../Redux/Enquiry/action";
import { useNavigate } from "react-router-dom";


const Footer = ({ setToggle, setCatId }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleQuickForm = (id) => {
    navigate("/")
    scrollToTop()
    setCatId(id)

    if (id) {
      setToggle({
        start: false,
        category: false,
        field: true,
      });

      dispatch(fetchGetLeadForm(id));
    } else {
      toast.error("Please Select Category");
    }
  }

  return (
    <div className="w-full bg-primary py-16">
      {/* <div className="w-11/12 lg:w-3/4 mx-auto"> */}
      <div className="px-10 mx-auto">



        {/* <div className="border border-red-500 grid grid-cols-12 sm:gap-x-10 h-full">
          <div className="col-span-12 sm:col-span-6 xl:col-span-7">
            <div className="flex items-center gap-x-10">
              <a href="https://play.google.com/store/apps/details?id=com.classiestate">
                <img
                  src={playStore}
                  alt="play store"
                  className="w-36 h-10 lg:w-44 lg:h-14 cursor-pointer"
                />
              </a>

              <a href="https://apps.apple.com/au/app/classiestate/id1562134094">
                <img
                  src={appStore}
                  alt="app store"
                  className="w-36 h-10 lg:w-44 lg:h-14 cursor-pointer"
                />
              </a>
            </div>
            <p className="mt-12 text-white text-sm lg:text-base textjustify">
              Classileads is Australia's leading online marketplace for tradespeople, connecting homeowners with reliable professionals to streamline home improvement projects.
              Through Classileads, homeowners can easily find local tradespeople for their needs, ensuring quality outcomes for their projects.
              For tradespeople, hipages offers a platform to expand their businesses by providing access to high-quality leads and integrated job management tools, reducing administrative burdens.
            </p>
          </div>

          <div className="border border-black w-full col-span-12 mt-10 sm:mt-0 sm:col-start-7 xl:col-start-8 col-end-13">
            <h6 className="font-medium text-white text-xl underline">
              Company
            </h6>
            <Link to={`/listing`}>
              <p className="text-white mt-3 hover:underline cursor-pointer">
                Directory
              </p>
            </Link>
            <Link to={`/blog`} onClick={() => scrollToTop()}>
              <p className="text-white mt-3 hover:underline cursor-pointer">
                Blog
              </p>
            </Link>
          </div>
        </div> */}


        <div className="text-white flex flex-wrap md:flex-nowrap justify-center items-center gap-10">

          <div className="w-full md:w-1/2">

            <div >
              <div className="flex items-center gap-x-10">
                <a href="https://play.google.com/store/apps/details?id=com.classiestate">
                  <img
                    src={playStore}
                    alt="play store"
                    className="w-36 h-10 lg:w-44 lg:h-14 cursor-pointer"
                  />
                </a>

                <a href="https://apps.apple.com/au/app/classiestate/id1562134094">
                  <img
                    src={appStore}
                    alt="app store"
                    className="w-36 h-10 lg:w-44 lg:h-14 cursor-pointer"
                  />
                </a>
              </div>
              <p className="mt-12 text-white text-sm lg:text-base textjustify">
                Classileads is Australia's leading online marketplace for tradespeople, connecting homeowners with reliable professionals to streamline home improvement projects.
                Through Classileads, homeowners can easily find local tradespeople for their needs, ensuring quality outcomes for their projects.
                For tradespeople, hipages offers a platform to expand their businesses by providing access to high-quality leads and integrated job management tools, reducing administrative burdens.
              </p>
            </div>

          </div>


          <div className="w-full md:w-1/2 flex justify-around items-start gap-x-5 gap-y-10 flex-wrap lg:flex-nowrap">



            <div className="w-fit">
              <h6 className="font-medium text-white text-xl underline">
                Features
              </h6>
              <Link to={`/advertise-with-us`}>
                <p className="text-white mt-3 hover:underline cursor-pointer">
                  Advertise with us
                </p>
              </Link>
              <a target="_blank" rel="noreferrer" href="https://clients.classileads.com.au/" onClick={() => scrollToTop()}>
                <p className="text-white mt-3 hover:underline cursor-pointer">
                  Agent portal
                </p>
              </a>
            </div>


            <div className="w-fit">
              <h6 className="font-medium text-white text-xl underline">
                Company
              </h6>
              <Link to={`/listing`}>
                <p className="text-white mt-3 hover:underline cursor-pointer">
                  Directory
                </p>
              </Link>
              <Link to={`/blog`} onClick={() => scrollToTop()}>
                <p className="text-white mt-3 hover:underline cursor-pointer">
                  Blog
                </p>
              </Link>
              <Link to={`/contact-us`} onClick={() => scrollToTop()}>
                <p className="text-white mt-3 hover:underline cursor-pointer">
                  Contact
                </p>
              </Link>
            </div>


            <div className="w-fit">
              <h6 className="font-medium text-white text-xl underline">
                Quick Links
              </h6>
              {/* <Link to={`/listing`}> */}
              <p onClick={() => handleQuickForm(5)} className="text-white mt-3 hover:underline cursor-pointer">
                Plumber
              </p>
              {/* </Link> */}
              {/* <Link to={`/blog`} onClick={() => scrollToTop()}> */}
              <p onClick={() => handleQuickForm(136)} className="text-white mt-3 hover:underline cursor-pointer">
                Electrician
              </p>
              {/* </Link> */}
              {/* <Link to={`/blog`} onClick={() => scrollToTop()}> */}
              <p onClick={() => handleQuickForm(68)} className="text-white mt-3 hover:underline cursor-pointer">
                Carpenter
              </p>
              {/* </Link> */}
              {/* <Link to={`/blog`} onClick={() => scrollToTop()}> */}
              <p onClick={() => handleQuickForm(147)} className="text-white mt-3 hover:underline cursor-pointer">
                Painter
              </p>
              {/* </Link> */}
              {/* <Link to={`/blog`} onClick={() => scrollToTop()}> */}
              <p onClick={() => handleQuickForm(78)} className="text-white mt-3 hover:underline cursor-pointer">
                Cleaner
              </p>
              {/* </Link> */}
              {/* <Link to={`/blog`} onClick={() => scrollToTop()}> */}
              <p onClick={() => handleQuickForm(172)} className="text-white mt-3 hover:underline cursor-pointer">
                Gardener
              </p>
              {/* </Link> */}
              {/* <Link to={`/blog`} onClick={() => scrollToTop()}> */}
              <p onClick={() => handleQuickForm(189)} className="text-white mt-3 hover:underline cursor-pointer">
                Handyman
              </p>
              {/* </Link> */}
            </div>


          </div>

        </div>




        <hr className="mt-12" />
        <div className="w-full sm:w-3/4 lg:w-1/2 mx-auto mt-14">
          <div className="flex justify-center items-center gap-6">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/classibazaar">
              <FaFacebookF size={27} className="text-white cursor-pointer" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/classibazaar">
              <FaInstagram size={27} className="text-white cursor-pointer" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/classibazaar">
              <FaTwitter size={27} className="text-white cursor-pointer" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/classibazaar/">
              <FaLinkedin size={27} className="text-white cursor-pointer" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCm1gM4xyWUa-_OL7QiF14Ng">
              <FaYoutube size={27} className="text-white cursor-pointer" />
            </a>
          </div>
          <div className="flex justify-between mt-16 full text-white">
            <p className="border-r w-full text-center text-sm lg:text-base cursor-pointer hover:underline">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>

            <p className="w-full text-center text-sm lg:text-base cursor-pointer hover:underline">
              <Link to="/terms-of-use">Terms & Conditions</Link>
            </p>
          </div>
          <p className="text-center mt-12 text-white text-sm lg:text-base">
            Copyright ©️ 2024 Classi International Pty Ltd trading as Classibazaar. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
