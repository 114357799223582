import React from 'react'

const CompanyDetails = ({ compData }) => {
    console.log(compData)
    return (
        <div className='bg-white border rounded-lg divide-y' >

            <div>
                <div className='p-6 pb-0 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                    <div>
                        <div className='text-sm text-gray-500'>Name</div>
                        <div className='text-sm'>{compData.company_name}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>Location</div>
                        <div className='text-sm'>{compData.address}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>When to start</div>
                        <div className='text-sm'>{compData.when_start}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>State</div>
                        <div className='text-sm'>{compData.state}</div>
                    </div>
                    {compData.suburb &&
                        <div>
                            <div className='text-sm text-gray-500'>Suburb</div>
                            <div className='text-sm'>{compData.suburb}</div>
                        </div>
                    }
                    <div>
                        <div className='text-sm text-gray-500'>Description</div>
                        <div className='text-sm'>{compData.description}</div>
                    </div>
                </div>

                <div className='p-6'>
                    <div className='text-sm text-gray-500'>Credit Subscription Expired Date</div>
                    <div className='text-sm'>{compData.enquiry_credit_subscription_expired_date}</div>
                </div>
            </div>

            {/* Contact Details */}
            <div>
                <div>
                    <div className='p-6 text-xl font-semibold w-fit '>Contact Details</div>
                    <hr className='ml-6 bg-primary w-20 h-0.5' />
                </div>

                <div className='p-6 gap-x-4 gap-y-6 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                    <div>
                        <div className='text-sm text-gray-500'>Phone</div>
                        <div className='text-sm'>{compData.contact_number}</div>
                    </div>
                    <div>
                        <div className='text-sm text-gray-500'>Email</div>
                        <div className='text-sm'>{compData.email_address}</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CompanyDetails