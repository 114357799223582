import React, { useCallback, useEffect, useState } from "react";
import { OTP } from "../../Component";
import { logo } from "../../Asset/Assest";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchResendOTP, removeAuthData } from "../../Redux/Auth/action";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading/Loading";

const VerifyAccount = () => {
  const [timer, setTimer] = useState(60);
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();

  const email = localStorage.getItem("email");

  const resendSuccess = useSelector(
    (state) => state.auth.resendMsg,
    shallowEqual
  );

  const handleResendOTP = () => {
    // setTimer(60);
    resetTimer()
    const body = {
      email: email,
    };
    dispatch(fetchResendOTP(body));
    setInProgress(true);
  };



  /*******************************************NEW OTP TIMER******************************************* */
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(60);
    }
  };

  /**************************************************************************************************** */

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimer((prevTimer) => prevTimer - 1);
  //   }, 1000);

  //   // Clear the interval when the timer reaches 0
  //   setTimeout(() => {
  //     clearInterval(interval);
  //   }, 60000); // 60 seconds
  // }, []);


  useEffect(() => {
    resendSuccess && toast.success(resendSuccess);
    resendSuccess && dispatch(removeAuthData());
    resendSuccess && setInProgress(false);
  }, [resendSuccess]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-12 w-full">
      <div className="w-11/12 sm:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto border bg-white shadow-md p-5 sm:p-7 sm:mt-10 rounded-xl">
        <div className="flex justify-center items-center flex-col">
          <div className="w-1/2 sm:w-5/12 h-8 sm:h-12 xl:h-9">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <h4 className="font-semibold text-textPrimary text-2xl mt-6">
            Verify Your Email Address
          </h4>
        </div>

        <div className="mt-10 text-center">
          <p className="text-textSecondary mt-2">
            OTP has been sent to your Email address. Please enter it below.
          </p>
          <OTP />
          <div className="mt-5">
            {timer <= 0 ? (
              <button
                onClick={handleResendOTP}
                className="border border-primary text-primary py-3 w-full text-center rounded-xl"
              >
                Resend OTP
              </button>
            ) : (
              <span>Resend OTP in {timer} seconds</span>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
